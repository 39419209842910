import PuzzleButton from '../PuzzleButton';
import useStore from '../react/useStore';
import Room from '../Room';
import { shuffleArray } from '../util';
import Puzzle from './Puzzle';

export default class Path extends Puzzle {
  generate(): void {
    const buttonPosition: number[][] = [[3, 3]];
    let puzzleButtons: PuzzleButton[] = [];
    for (let i = 0; i < buttonPosition.length; i += 1) {
      const puzzleButton = new PuzzleButton();
      puzzleButton.addToScene(
        this.scene,
        [
          this.room.tilePosition[0] * Room.ROOM_SIZE + buttonPosition[i][0],
          this.room.tilePosition[1] * Room.ROOM_SIZE + buttonPosition[i][1],
        ],
        '',
      );
      puzzleButton.sprite.setDepth(1);
      puzzleButtons[i] = puzzleButton;
      this.scene.updaters.push((time, delta) => {
        puzzleButton.update(time, delta);
      });
    }

    const flattenedButtons = [...puzzleButtons.flat()];
    shuffleArray(flattenedButtons);

    const { dude } = this.scene;

    let movementStarted = false;

    dude.sprite.on('move', () => {
      if (this.completed) {
        return;
      }
      puzzleButtons
        .flat()
        .forEach(puzzleButton =>
          puzzleButton.sprite.setAlpha(
            this.room === this.getCurrentRoom()
              ? 1
              : this.room.tiles.flat()[0].alpha,
          ),
        );
      const pressedButton = puzzleButtons
        .flat()
        .find(
          thing =>
            thing.tilePosition[0] === dude.tilePosition[0] &&
            thing.tilePosition[1] === dude.tilePosition[1],
        );
      if (
        pressedButton &&
        pressedButton === puzzleButtons[puzzleButtons.length - 1]
      ) {
        pressedButton.press();
        movementStarted = true;

        pressedButton.sprite.alpha = 1;
        pressedButton.tween?.stop();

        if (
          puzzleButtons.length === 7 &&
          puzzleButtons.flat().every(button => button.pressed)
        ) {
          this.addArtifact();
          this.completed = true;
          puzzleButtons
            .flat()
            .forEach(button => button.sprite.setTint(0x00ff00));

          this.scene.time.addEvent({
            delay: 1000,
            callback: () => {
              puzzleButtons.flat().forEach(button => button.sprite.destroy());
            },
          });
          return;
        }

        const adjacentPositions = [
          [pressedButton.tilePosition[0] - 1, pressedButton.tilePosition[1]],
          [pressedButton.tilePosition[0] + 1, pressedButton.tilePosition[1]],
          [pressedButton.tilePosition[0], pressedButton.tilePosition[1] - 1],
          [pressedButton.tilePosition[0], pressedButton.tilePosition[1] + 1],
        ].filter(
          position =>
            position[0] % Room.ROOM_SIZE >= 1 &&
            position[0] % Room.ROOM_SIZE < this.room.tiles.length - 1 &&
            position[1] % Room.ROOM_SIZE >= 1 &&
            position[1] % Room.ROOM_SIZE < this.room.tiles[0].length - 1 &&
            !puzzleButtons.some(
              button =>
                button.tilePosition[0] === position[0] &&
                button.tilePosition[1] === position[1],
            ),
        );
        shuffleArray(adjacentPositions);
        const puzzleButton = new PuzzleButton();
        puzzleButton.addToScene(
          this.scene,
          [adjacentPositions[0][0], adjacentPositions[0][1]],
          '',
        );
        puzzleButton.sprite.setDepth(1);
        puzzleButton.sprite.alpha = 1;
        puzzleButtons.push(puzzleButton);
        puzzleButton.tween = this.scene.tweens.add({
          targets: puzzleButton.sprite,
          alpha: 0,
          duration: 2500 - puzzleButtons.length * 150,
          onComplete: () => {
            if (puzzleButton.pressed) {
              return;
            }
            movementStarted = false;
            puzzleButtons = this.restartPuzzle(puzzleButtons);
          },
        });
      } else if (movementStarted) {
        movementStarted = false;
        puzzleButtons = this.restartPuzzle(puzzleButtons);
      }
    });
  }

  private restartPuzzle(puzzleButtons: PuzzleButton[]) {
    const [first, ...rest] = puzzleButtons;
    rest.forEach(puzzleButton => puzzleButton.sprite.destroy());
    rest.forEach(puzzleButton => puzzleButton.tween?.stop());
    puzzleButtons = [first];
    puzzleButtons[0].release();
    useStore.getState().setNoMistakes(false);
    return puzzleButtons;
  }
}
