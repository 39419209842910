import { format } from 'date-fns';
import Phaser from 'phaser';
import Dungeon from './Dungeon';
import Room from './Room';
import { start } from './util';

export default class TextTile {
  tilePosition: number[];

  pathToKey: Room[] = [];

  destructionImminent: boolean;

  text: Phaser.GameObjects.Text;

  room: Room;

  addToScene(scene: Phaser.Scene, tilePosition: number[], text: string) {
    this.tilePosition = tilePosition;
    this.text = scene.add.text(
      this.tilePosition[1] * Dungeon.TILE_SIZE,
      this.tilePosition[0] * Dungeon.TILE_SIZE,
      text,
      { fontSize: '21px', fontFamily: 'Courier New' },
    );
    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.text.setPipeline('Light2D');
    }
  }

  update(time: number, delta: number): void {
    this.text.x +=
      (this.tilePosition[1] * Dungeon.TILE_SIZE - this.text.x) * delta * 0.003;
    this.text.y +=
      (this.tilePosition[0] * Dungeon.TILE_SIZE - this.text.y) * delta * 0.003;
  }
}
