/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Popper,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { Share } from '@mui/icons-material';
import { useTimer } from 'react-timer-hook';
import { mergeStyles } from '@fluentui/merge-styles';
import { format, intervalToDuration } from 'date-fns';
import {
  daysSinceGameWentPublic,
  isEndlessMode,
  isMobile,
  setMode,
  start,
} from '../util';
import useStore from './useStore';

export const determineStepsTaken = () => {
  const { stepsTaken } = useStore.getState();

  return `👟 ${stepsTaken}`;
};

export const determineTimeTaken = () => {
  const store = useStore.getState();

  const timeTaken = intervalToDuration({
    start: 0,
    end: store.timeTaken * 1000,
  });

  return `⏱ ${timeTaken.minutes
    ?.toString()
    .padStart(2, '0')}:${timeTaken.seconds?.toString().padStart(2, '0')}`;
};

const determineAverageTimeTaken = () => {
  const store = useStore.getState();

  const timeTaken = intervalToDuration({
    start: 0,
    end: store.averageTimeTaken * 1000,
  });

  return `${timeTaken.minutes?.toString().padStart(2, '0')}:${timeTaken.seconds
    ?.toString()
    .padStart(2, '0')}`;
};

const TOOLTIPS = {
  '⚡️': 'Speed of thought',
  '⏩': 'You are speed',
  '💨': 'Woosh!',
  '👠': 'Watch your step',
  '✨': 'First time for everything',
  '🚪': 'Welcome back',
  '⭐️': "Don't drop it!",
  '🧠': 'Mistakes are for the weak',
  '❗️': 'Follow the secrets',
  '❓': 'Hidden in plain sight',
  '👁': 'The all-seeing eye',
  '🤫': 'Sssshhh',
  '🔎': 'A true explorer',
  '👑': 'You are a legend',
  '🍾': 'Fifty-fifty',
  '🎊': 'Party of the century!',
  '🎉': 'Party of the decade!',
  '🎈': 'High five!',
  '🙌': 'Keep coming back for more',
  '🎂': "It's a lie?",
  '🍰': 'Have your cake and eat it too',
  '🍀': 'Lady Luck is on your side',
  '🌿': 'La-da-da-da-dah',
  '✂️': 'Plant killer',
  '🌷': 'Green thumb',
  '💕': 'Leave two behind',
  '🎩': 'Magic is in the air',
  '😑': '😑',
  '🐇': 'Down the rabbit hole',
  '⤴️': 'Who needs those silly keys anyway?',
  '💰': 'You are rich',
  '📱': 'Mobile friendly',
};

const generateClipboardText = () =>
  `${location.hostname} #${
    daysSinceGameWentPublic + 1
  }\n${determineStepsTaken()}\n${determineTimeTaken()}\n${useStore
    .getState()
    .badgesCollectedToday?.join('')}`;

const CompletedDialog: FC = () => {
  const store = useStore();
  const [copied, setCopied] = useState(false);
  const [currentlySelectedBadge, setCurrentlySelectedBadge] = useState<
    string | null
  >(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [mailchimpOpen, setMailchimpOpen] = useState(false);

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp: new Date(start.getTime() + 1000 * 60 * 60 * 24 * 7),
  });

  const [tab, setTab] = useState<'Statistics' | 'Collection' | 'Shop'>(
    'Statistics',
  );

  const badgesCollectedToday = useStore(st => st.badgesCollectedToday);
  const badgesCollected = useStore(st => st.badgesCollected);
  const previouslyCollectedBadges = useStore(
    st => st.previouslyCollectedBadges,
  );

  return (
    <>
      <Snackbar
        open={copied}
        onClose={() => setCopied(false)}
        autoHideDuration={3000}
        message="Copied to Clipboard"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <Dialog
        fullWidth
        open={store.dialogOpen}
        classes={{
          paper: mergeStyles({
            width: isMobile() ? '90vw' : '36vw',
            maxWidth: isMobile() ? '90vw !important' : '40vw !important',
            height: isMobile() ? '80vh' : 725,
            fontSize: isMobile() ? 48 : 20,
          }),
        }}
      >
        <DialogTitle
          style={{
            fontSize: isMobile() ? 48 : 32,
            paddingLeft: isMobile() ? 36 : 20,
            paddingTop: isMobile() ? 24 : 20,
            display: 'flex',
            flexDirection: isMobile() ? 'column' : 'row',
            justifyContent: 'space-between',
            whiteSpace: 'pre',
          }}
        >
          <div>
            {format(start, 'yyyy-MM-dd') === '2022-05-13'
              ? '👻 👻 👻'
              : 'Dungeon Completed'}
          </div>
          <Button
            href="https://threads.net/hi.im.vijay"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<img src="./Threads.svg" height={isMobile() ? 32 : 20 } />}
          >
            Follow me on Threads
          </Button>
        </DialogTitle>
        <DialogContent
          style={{
            paddingLeft: isMobile() ? 36 : 20,
            overflow: 'hidden',
          }}
        >
          <Tabs
            value={tab}
            onChange={(e, t) => setTab(t)}
            aria-label="basic tabs example"
          >
            <Tab
              label="Statistics"
              value="Statistics"
              style={{ fontSize: isMobile() ? 32 : 24 }}
            />
            <Tab
              label="Collection"
              value="Collection"
              style={{ fontSize: isMobile() ? 32 : 24 }}
            />
            {!isEndlessMode() && (
              <Tab
                label="Shop"
                value="Shop"
                style={{ fontSize: isMobile() ? 32 : 24 }}
              />
            )}
          </Tabs>
          <div
            style={{
              display: tab === 'Statistics' ? 'block' : 'none',
            }}
          >
            <div>
              <Divider style={{ marginBottom: 16 }} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '95%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <span style={{ textAlign: 'center' }}>
                  {Math.floor(store.totalPlayed)}
                  <Typography component="div" variant="caption">
                    Played
                  </Typography>
                </span>
                {!isEndlessMode() && (
                  <>
                    <span style={{ textAlign: 'center' }}>
                      {Math.floor(store.dailyStreak)}
                      <Typography component="div" variant="caption">
                        Current
                        <br />
                        Streak
                      </Typography>
                    </span>
                    <span style={{ textAlign: 'center' }}>
                      {Math.floor(store.maxDailyStreak)}
                      <Typography component="div" variant="caption">
                        Max
                        <br />
                        Streak
                      </Typography>
                    </span>
                  </>
                )}
                <span style={{ textAlign: 'center' }}>
                  {determineAverageTimeTaken()}
                  <Typography component="div" variant="caption">
                    Average
                    <br />
                    Time
                  </Typography>
                </span>
                {store.averageStepsTaken > 0 && (
                  <span style={{ textAlign: 'center' }}>
                    {Math.floor(store.averageStepsTaken)}
                    <Typography component="div" variant="caption">
                      Average
                      <br />
                      Steps
                    </Typography>
                  </span>
                )}
              </div>
              {!isEndlessMode() && (
                <>
                  <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                  <div>💰 {store.tokensCollected}</div>
                </>
              )}
              <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            </div>
            <div>{determineStepsTaken()}</div>
            <div>{determineTimeTaken()}</div>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            <Typography
              variant="caption"
              component="div"
              style={{ marginTop: 16, marginBottom: 16, textAlign: 'center' }}
            >
              {isMobile()
                ? 'Touch the badges to learn more about them!'
                : 'Hover over the badges to learn more about them!'}
            </Typography>
            <div
              style={{
                fontSize: isMobile() ? 50 : 24,
                marginTop: 16,
                textAlign: 'center',
              }}
              aria-label="badges"
            >
              {badgesCollectedToday?.map((badge, index) => (
                <Fade
                  in
                  key={index}
                  timeout={1000}
                  style={{ transitionDelay: `${300 * index}ms` }}
                >
                  <span
                    onClick={e => {
                      if (isMobile()) {
                        setPopoverAnchor(e.currentTarget);
                        setPopoverOpen(true);
                        setCurrentlySelectedBadge(badge);
                      }
                    }}
                    style={{ padding: isMobile() ? 16 : 8 }}
                  >
                    {!isMobile() && (
                      <Tooltip title={TOOLTIPS[badge]}>
                        <Badge
                          variant="dot"
                          color="primary"
                          invisible={previouslyCollectedBadges.includes(badge)}
                        >
                          <span>{badge}</span>
                        </Badge>
                      </Tooltip>
                    )}
                    {isMobile() && <span>{badge}</span>}
                  </span>
                </Fade>
              ))}
            </div>
            {(store.endlessModePurchased || isEndlessMode()) && (
              <Divider style={{ marginTop: 16 }} />
            )}
            {store.endlessModePurchased && !isEndlessMode() && (
              <Button
                fullWidth
                style={{
                  fontSize: isMobile() ? 32 : 20,
                }}
                onClick={() => {
                  setMode('endless');
                }}
              >
                Switch to Endless Mode
              </Button>
            )}
            {isEndlessMode() && (
              <Button
                fullWidth
                style={{
                  fontSize: isMobile() ? 32 : 20,
                }}
                onClick={() => {
                  setMode('daily');
                }}
              >
                Switch to Daily Mode
              </Button>
            )}
            {(store.endlessModePurchased || isEndlessMode()) && (
              <Divider style={{ marginBottom: 16 }} />
            )}
          </div>
          <div
            style={{
              display: tab === 'Collection' ? 'block' : 'none',
              backgroundColor: '#4a4a4a',
            }}
          >
            <Divider style={{ marginBottom: 16 }} />
            <div
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                fontSize: isMobile() ? 72 : 38,
              }}
            >
              {Object.keys(TOOLTIPS)
                .filter(badge => (isMobile() ? true : badge !== '📱'))
                .map((badge, index) => (
                  <span
                    key={index}
                    onClick={e => {
                      if (isMobile()) {
                        setPopoverAnchor(e.currentTarget);
                        setPopoverOpen(true);
                        setCurrentlySelectedBadge(badge);
                      }
                    }}
                    style={{
                      padding: isMobile() ? 16 : 8,
                      cursor: 'pointer',
                      textShadow: badgesCollected.includes(badge)
                        ? ''
                        : 'rgb(255 255 255 / 70%) -3px -3px 0px, rgb(0 0 0 / 80%) 3px 3px 0px',
                    }}
                  >
                    <span
                      style={{
                        filter: badgesCollected.includes(badge)
                          ? ''
                          : `grayscale(100%) blur(${
                              isMobile() ? 4 : 3
                            }px) opacity(0.2) drop-shadow(10px 10px 10px rgba(0,0,0,0.9))`,
                      }}
                    >
                      {!isMobile() && (
                        <Tooltip
                          title={
                            badgesCollected.includes(badge)
                              ? TOOLTIPS[badge]
                              : '???'
                          }
                        >
                          <span>{badge}</span>
                        </Tooltip>
                      )}
                      {isMobile() && <span>{badge}</span>}
                    </span>
                  </span>
                ))}
            </div>
          </div>
          <div
            style={{
              display: tab === 'Shop' ? 'block' : 'none',
            }}
          >
            <Divider style={{ marginBottom: 16 }} />
            <div>💰 {store.tokensCollected}</div>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            {!store.endlessModePurchased && (
              <Button
                disabled={store.tokensCollected < 1}
                onClick={() => {
                  store.setEndlessModePurchased(true);
                  store.setTokensCollected(store.tokensCollected - 1);
                }}
              >
                Endless Mode (1 💰)
              </Button>
            )}
            {store.endlessModePurchased && <>Nothing here... for now</>}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px 24px',
          }}
        >
          {!isEndlessMode() && (
            <>
              <div
                style={{
                  fontSize: isMobile() ? 32 : 20,
                  paddingLeft: isMobile() ? 4 : 0,
                }}
              >
                Next Dungeon: {hours}:
                {minutes.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
                :
                {seconds.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
              </div>
              <Button
                variant="contained"
                size="large"
                style={{
                  fontSize: isMobile() ? 32 : 20,
                  marginLeft: 'auto',
                }}
                startIcon={<Share style={{ fontSize: isMobile() ? 32 : 20 }} />}
                onClick={() => {
                  if (isMobile() && navigator.share) {
                    navigator.share({ title: generateClipboardText() });
                  } else {
                    copy(generateClipboardText());
                    setCopied(true);
                  }
                }}
              >
                Share
              </Button>
            </>
          )}
          {isEndlessMode() && (
            <Button
              variant="contained"
              size="large"
              style={{
                fontSize: isMobile() ? 32 : 20,
                marginLeft: 'auto',
              }}
              onClick={() => {
                location.reload();
              }}
            >
              Play Again
            </Button>
          )}
        </DialogActions>
        <Divider />
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button href='https://semantical.fun' target="_blank" rel="noopener noreferrer">
            Check out my new game, Semantical!
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={mailchimpOpen} onClose={() => setMailchimpOpen(false)}>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 16,
          }}
        >
          <div style={{ padding: 4 }}>
            I&apos;m working on a spritual successor to Daily Dungeon.
            <br />
            Sign up for the mailing list to stay up to date on the progress! (I
            won&apos;t spam you, I promise)
          </div>
          <MailchimpSubscribe
            url={
              'https://dailydungeon.us14.list-manage.com/subscribe/post?u=342767716f9e7a185a2402ac3&amp;id=2b109bd987&amp;f_id=00b981e0f0'
            }
          />
        </DialogContent>
      </Dialog>
      <Popper
        open={popoverOpen}
        anchorEl={popoverAnchor}
        style={{ zIndex: 9999 }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setPopoverOpen(false);
          }}
        >
          <Box
            sx={{ border: 1, p: 1, bgcolor: 'Background.paper' }}
            style={{ color: 'white', fontSize: 38, backgroundColor: '#333' }}
          >
            {badgesCollected.includes(currentlySelectedBadge!)
              ? TOOLTIPS[currentlySelectedBadge!]
              : '???'}
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default CompletedDialog;
