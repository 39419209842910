import useStore from '../react/useStore';
import Room from '../Room';
import TextTile from '../TextTile';
import { DEFAULT_ALPHA, shuffleArray } from '../util';
import Puzzle from './Puzzle';
import words from './words.json';

export default class WordPuzzle extends Puzzle {
  generate(): void {
    const textTilePositions: number[][] = [
      [1, 1],
      [1, 2],
      [1, 3],
      [1, 4],
      [1, 5],
    ];
    const shuffledWords = words as string[];
    shuffleArray(shuffledWords);
    const expression = shuffledWords[0].split('');
    const textTiles: TextTile[] = [];
    shuffleArray(expression);
    while (shuffledWords.includes(expression.join(''))) {
      shuffleArray(expression);
    }
    for (let i = 0; i < textTilePositions.length; i += 1) {
      const textTile = new TextTile();
      textTile.addToScene(
        this.scene,
        [
          this.room.tilePosition[0] * Room.ROOM_SIZE -
            0.5 +
            textTilePositions[i][0],
          this.room.tilePosition[1] * Room.ROOM_SIZE -
            0.5 +
            textTilePositions[i][1],
        ],
        expression[i].toString(),
      );
      textTile.text.setDepth(1);
      textTile.text.alpha = DEFAULT_ALPHA;
      textTiles.push(textTile);
    }

    const { dude } = this.scene;

    let swaps = 0;

    let previouslyPressed: TextTile | null = null;
    const solved = false;
    let justSteppedOnATextTile = false;
    let justSlid = false;
    dude.sprite.on('move', () => {
      if (solved) {
        return;
      }
      if (this.completed) {
        return;
      }

      textTiles.forEach(textTile =>
        textTile.text.setAlpha(
          this.room === this.getCurrentRoom()
            ? 1
            : this.room.tiles.flat()[0].alpha,
        ),
      );
      textTiles.forEach(textTile => textTile.text.setTint(0xffffff));
      const pressedTextTile = textTiles.find(
        thing =>
          Math.ceil(thing.tilePosition[0]) === dude.tilePosition[0] &&
          Math.ceil(thing.tilePosition[1]) === dude.tilePosition[1],
      );
      if (pressedTextTile) {
        if (previouslyPressed) {
          // swap the two
          const temp = pressedTextTile.text.text;
          pressedTextTile.text.text = previouslyPressed.text.text;
          previouslyPressed.text.text = temp;
          if (justSteppedOnATextTile) {
            previouslyPressed = pressedTextTile;
            justSlid = true;
          } else {
            previouslyPressed = null;
            justSlid = false;
          }
          swaps += 1;
        } else {
          previouslyPressed = pressedTextTile;
        }
        justSteppedOnATextTile = true;
      } else {
        if (justSlid) {
          justSlid = false;
          previouslyPressed = null;
          textTiles.forEach(textTile => textTile.text.setTint(0xffffff));
        }
        justSteppedOnATextTile = false;
      }

      if (swaps > 10) {
        useStore.getState().setPerfectGame(false);
      }

      if (previouslyPressed) {
        previouslyPressed.text.tint = 0xaaaa00;
      }

      if (
        shuffledWords.some(
          word =>
            word === textTiles.map(textTile => textTile.text.text).join(''),
        )
      ) {
        textTiles.forEach(textTile => textTile.text.setTint(0x00ff00));
        this.scene.time.addEvent({
          delay: 1000,
          callback: () => {
            textTiles.forEach(textTile => textTile.text.setAlpha(0));
            this.completed = true;
          },
        });
        this.addArtifact();
      }
    });
  }
}
