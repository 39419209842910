import Artifact from './Artifact';
import Cake from './Cake';
import Collectible from './Collectible';
import DungeonGenerator from './DungeonGenerator';
import Exit from './Exit';
import Fork from './Fork';
import Key from './Key';
import Lock from './Lock';
import Planter from './Planter';
import Room from './Room';
import Main from './scenes/Main';
import Seed from './Seed';
import Warp from './Warp';

export default class Dungeon {
  static MAP_SIZE = 25;

  static TILE_SIZE = 16;

  static MIDPOINT = Math.floor(Dungeon.MAP_SIZE / 2);

  rooms: Room[][] = [];

  keys: Key[] = [];

  artifacts: Artifact[] = [];

  locks: Lock[] = [];

  exit: Exit | null;

  warpTiles: Warp[];

  seed: Seed;

  planter: Planter;

  tokenLock: Lock;

  tokenRoomExit: Exit;

  cake: Cake;

  fork: Fork | null;

  static generate(scene: Main): Dungeon {
    const dungeon = new Dungeon();
    new DungeonGenerator(dungeon, scene).generate();
    return dungeon;
  }

  getCollectibles(): Collectible[] {
    return [
      ...(this.seed.planted ? [] : [this.seed]),
      ...(this.fork ? [this.fork] : []),
      ...this.keys,
      ...this.artifacts,
    ];
  }
}
