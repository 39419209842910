/* eslint-disable no-loop-func */
import Phaser from 'phaser';
import { format } from 'date-fns';
import useStore from '../react/useStore';
import Dude from '../Dude';
import Dungeon from '../Dungeon';
import MovementManager from '../MovementManager';
import Preloader from '../Preloader';
import { start } from '../util';

export let swipeRef: any;

export default class Main extends Phaser.Scene {
  dude: Dude;

  dungeon: Dungeon;

  movementManager: MovementManager;

  elapsedTimer: Phaser.Time.TimerEvent;

  updaters: ((time: number, delta: number) => void)[] = [];

  light: Phaser.GameObjects.Light;

  constructor() {
    super('main');
  }

  preload() {
    Preloader.preload(this);
  }

  create() {
    var swipe = this.rexGestures.add.swipe({
      enable: true,
      bounds: undefined,

      threshold: 10,
      velocityThreshold: 1000,
      dir: '4dir',
    });
    swipeRef = swipe;
    swipe.on('swipe', (swipe, gameObject, lastPointer) => {
      if (swipe.up) {
        this.movementManager.handleUp();
      }
      if (swipe.down) {
        this.movementManager.handleDown();
      }
      if (swipe.left) {
        this.movementManager.handleLeft();
      }
      if (swipe.right) {
        this.movementManager.handleRight();
      }
    });
    this.lights.enable();

    if (useStore.getState().demoMode) {
      this.cameras.main.zoom = window.innerHeight / 112;
    } else {
      this.cameras.main.zoom = window.innerHeight / 400;
    }
    this.dude = new Dude();
    this.dude.addToScene(this);
    this.dude.follow(this);

    this.dungeon = Dungeon.generate(this);

    this.movementManager = new MovementManager(this.dude, this.dungeon);

    this.movementManager.resolveMovement(this);

    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.light = this.lights
        .addLight(400, 300, 200)
        .setColor(0xffaaaa)
        .setIntensity(6)
        .setRadius(75);
    }

    this.elapsedTimer = this.time.addEvent({
      delay: 1000,
      callback: () => {
        useStore.getState().setTimeTaken(useStore.getState().timeTaken + 1);
      },
      loop: true,
    });
  }

  update(time: number, delta: number): void {
    this.dude.update(time, delta);
    [
      ...this.dungeon.keys,
      ...this.dungeon.artifacts,
      this.dungeon.seed,
      this.dungeon.fork,
    ]
      .filter(Boolean)
      .forEach(collectible => collectible!.update(time, delta));

    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.light.x = this.dude.sprite.x;
      this.light.y = this.dude.sprite.y;
    }

    this.dungeon.exit?.update(this.dude, this);
    this.dungeon.tokenRoomExit?.update(this.dude, this);
    this.dungeon.planter?.update();
    this.dungeon.warpTiles?.forEach(warpTile => warpTile?.update());
    this.dungeon.rooms
      .flat()
      .map(room => room.roomBehavior)
      .filter(Boolean)
      .forEach(
        room => room.update && room.update(this.dude, this.dungeon, this),
      );
    this.updaters.forEach(updater => updater(time, delta));
  }
}
