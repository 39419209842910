import Phaser from 'phaser';
import Collectible from './Collectible';
import Dungeon from './Dungeon';
import Room from './Room';
import Main from './scenes/Main';
import useStore from './react/useStore';

export default class Dude {
  sprite: Phaser.GameObjects.Sprite;

  tilePosition: number[];

  currentItem: Collectible | null = null;

  done: boolean;

  exiting: boolean;

  particles: Phaser.GameObjects.Particles.ParticleEmitterManager;

  emitter: Phaser.GameObjects.Particles.ParticleEmitter;

  constructor() {
    this.tilePosition = [
      Room.ROOM_SIZE * Math.floor(Dungeon.MAP_SIZE / 2) + 3,
      Room.ROOM_SIZE * Math.floor(Dungeon.MAP_SIZE / 2) + 3,
    ];
  }

  addToScene(scene: Main) {
    this.sprite = scene.add.sprite(
      this.tilePosition[1] * Dungeon.TILE_SIZE,
      this.tilePosition[0] * Dungeon.TILE_SIZE,
      'dude',
      1,
    );
    scene.anims.create({
      key: 'dude-idle',
      frames: scene.anims.generateFrameNumbers('dude', { start: 0, end: 3 }),
      frameRate: 5,
      repeat: -1,
    });
    this.sprite.play('dude-idle');
    scene.anims.create({
      key: 'dude-move',
      frames: [
        { key: 'dude', frame: 6, duration: 20 },
        { key: 'dude', frame: 7, duration: 30 },
        { key: 'dude', frame: 8, duration: 20 },
        { key: 'dude', frame: 4, duration: 30 },
        { key: 'dude', frame: 5, duration: 20 },
      ],
      repeat: 0,
    });
    this.sprite.setDepth(2);
    this.particles = scene.add.particles('particle');
    this.particles.setDepth(1);
    this.emitter = this.particles.createEmitter({
      x: this.sprite.x,
      y: this.sprite.y,
      // angle: this.sprite.rotation,
      // speed: { min: -100, max: 100 },
      lifespan: 300,
      scale: { start: 1.5, end: 0 },
      quantity: 2,
      blendMode: 'ADD',
      emitZone: {
        type: 'random',
        /* @ts-ignore */
        source: new Phaser.Geom.Circle(0, 4, 4),
      },
      alpha: { start: 0.5, end: 0 },
      active: true,
    });
    this.emitter.stop();
  }

  follow(scene: Phaser.Scene) {
    scene.cameras.main.startFollow(this.sprite, true, 0.2, 0.2);
    if (useStore.getState().demoMode) {
      scene.cameras.main.stopFollow();
    }
    scene.input.keyboard.addKey('Z', true).on('down', () => {
      scene.cameras.main.startFollow(this.sprite, true, 0.2, 0.2);
      scene.cameras.main.stopFollow();
    });
  }

  explode() {
    this.emitter.explode(4, this.sprite.x, this.sprite.y);
  }

  fireWalkAnimation() {
    this.sprite.play('dude-move');
    this.sprite.once(Phaser.Animations.Events.ANIMATION_COMPLETE, () => {
      this.sprite.play('dude-idle');
    });
  }

  update(time: number, delta: number): void {
    let speedX =
      (this.tilePosition[1] * Dungeon.TILE_SIZE - this.sprite.x) *
      delta *
      (this.exiting ? 0.001 : 0.02);
    if (
      Math.abs(speedX) >
      Math.abs(this.tilePosition[1] * Dungeon.TILE_SIZE - this.sprite.x)
    ) {
      speedX = this.tilePosition[1] * Dungeon.TILE_SIZE - this.sprite.x;
    }

    let speedY =
      (this.tilePosition[0] * Dungeon.TILE_SIZE - this.sprite.y) *
      delta *
      (this.exiting ? 0.001 : 0.02);

    if (
      Math.abs(speedY) >
      Math.abs(this.tilePosition[0] * Dungeon.TILE_SIZE - this.sprite.y)
    ) {
      speedY = this.tilePosition[0] * Dungeon.TILE_SIZE - this.sprite.y;
    }

    this.sprite.x += speedX;
    this.sprite.y += speedY;

    this.emitter.setPosition(this.sprite.x, this.sprite.y);
  }
}
