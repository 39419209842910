import PuzzleButton from '../PuzzleButton';
import useStore from '../react/useStore';
import Room from '../Room';
import TextTile from '../TextTile';
import { DEFAULT_ALPHA, shuffleArray } from '../util';
import Puzzle from './Puzzle';

export default class Memory extends Puzzle {
  generate(): void {
    const clueTextTiles: TextTile[] = [];
    const rooms = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(
        room => !['PUZZLE_ROOM', 'ARTIFACT_ROOM'].includes(room.roomTypeName!),
      );
    let letters = 'ABCDEFGHIJKLMNOPRSTUVWXYZ'.split('');
    const numberOfClues = 5;
    shuffleArray(letters);
    letters = letters.slice(0, numberOfClues);
    const intendedLetterOrder = [...letters];
    shuffleArray(rooms);
    for (let i = 0; i < numberOfClues; i += 1) {
      const textTile = new TextTile();
      const randomPosition = rooms[i].pickRandomPosition(true);

      let randomPosition2 = rooms[i].pickRandomPosition(true);
      while (
        randomPosition2[0] === randomPosition[0] &&
        randomPosition2[1] === randomPosition[1]
      ) {
        randomPosition2 = rooms[i].pickRandomPosition(true);
      }
      textTile.addToScene(
        this.scene,
        [
          rooms[i].tilePosition[0] * Room.ROOM_SIZE - 0.5 + randomPosition[0],
          rooms[i].tilePosition[1] * Room.ROOM_SIZE - 0.5 + randomPosition[1],
        ],
        (i + 1).toString(),
      );
      textTile.text.setDepth(1);
      textTile.room = rooms[i];
      textTile.text.alpha = DEFAULT_ALPHA;
      clueTextTiles.push(textTile);
      const textTile2 = new TextTile();
      textTile2.addToScene(
        this.scene,
        [
          rooms[i].tilePosition[0] * Room.ROOM_SIZE - 0.5 + randomPosition2[0],
          rooms[i].tilePosition[1] * Room.ROOM_SIZE - 0.5 + randomPosition2[1],
        ],
        letters[i],
      );
      textTile2.text.setDepth(1);
      textTile2.room = rooms[i];
      textTile2.text.alpha = DEFAULT_ALPHA;
      clueTextTiles.push(textTile2);
    }

    shuffleArray(letters);

    const puzzleTextTiles: TextTile[] = [];
    for (let i = 0; i < numberOfClues; i += 1) {
      const textTile = new TextTile();
      textTile.addToScene(
        this.scene,
        [
          this.room.tilePosition[0] * Room.ROOM_SIZE + 0.4,
          this.room.tilePosition[1] * Room.ROOM_SIZE - 0.5 + 1 + i,
        ],
        letters[i],
      );
      textTile.text.setDepth(1);
      textTile.text.alpha = DEFAULT_ALPHA;
      puzzleTextTiles.push(textTile);
    }

    const buttonPosition: number[][] = [
      [2, 1],
      [2, 2],
      [2, 3],
      [2, 4],
      [2, 5],
    ];
    const puzzleButtons: PuzzleButton[] = [];
    for (let i = 0; i < buttonPosition.length; i += 1) {
      const puzzleButton = new PuzzleButton();
      puzzleButton.addToScene(this.scene, [
        this.room.tilePosition[0] * Room.ROOM_SIZE + buttonPosition[i][0],
        this.room.tilePosition[1] * Room.ROOM_SIZE + buttonPosition[i][1],
      ]);
      puzzleButton.sprite.setDepth(1);
      puzzleButtons.push(puzzleButton);
    }

    let currentButton = 0;
    const { dude } = this.scene;

    dude.sprite.on('move', () => {
      if (this.completed) {
        return;
      }
      clueTextTiles.forEach(clueTextTile => {
        clueTextTile.text.setAlpha(
          clueTextTile.room === this.getCurrentRoom()
            ? 1
            : clueTextTile.room.tiles.flat()[0].alpha,
        );
      });
      puzzleTextTiles.forEach(puzzleTextTile =>
        puzzleTextTile.text.setAlpha(
          this.room === this.getCurrentRoom()
            ? 1
            : this.room.tiles.flat()[0].alpha,
        ),
      );
      puzzleButtons.forEach(puzzleButton =>
        puzzleButton.sprite.setAlpha(
          this.room === this.getCurrentRoom()
            ? 1
            : this.room.tiles.flat()[0].alpha,
        ),
      );
      const pressedButton = puzzleButtons.find(
        thing =>
          thing.tilePosition[0] === dude.tilePosition[0] &&
          thing.tilePosition[1] === dude.tilePosition[1],
      );
      if (!pressedButton) {
        return;
      }
      if (
        puzzleTextTiles[puzzleButtons.indexOf(pressedButton)].text.text ===
        intendedLetterOrder[currentButton]
      ) {
        puzzleButtons[puzzleButtons.indexOf(pressedButton)].press();
        currentButton += 1;
        if (currentButton === puzzleButtons.length) {
          clueTextTiles.forEach(textTile => textTile.text.destroy());
          puzzleTextTiles.forEach(textTile => textTile.text.destroy());
          puzzleButtons.forEach(thing => thing.sprite.destroy());
          this.addArtifact();
        }
      } else {
        currentButton = 0;
        useStore.getState().setNoMistakes(false);
        puzzleButtons.forEach(thing => thing.release());
      }
    });
  }
}
