import { format } from 'date-fns';
import Phaser from 'phaser';
import useStore from './react/useStore';
import './react/Root';
import Main from './scenes/Main';
import UI from './scenes/UI';
import { isEndlessMode, start } from './util';

const { dialogOpen, today } = useStore.getState();

// eslint-disable-next-line import/no-mutable-exports
let game: Phaser.Game | null = null;

const urlParams = new URLSearchParams(window.location.search);
if (
  urlParams.has('s') ||
  isEndlessMode() ||
  !dialogOpen ||
  (dialogOpen &&
    (today === null ||
      (today !== null && start.getTime() > new Date(today).getTime())))
) {
  useStore.setState({
    dialogOpen: false,
    helpDialogOpen: isEndlessMode()
      ? false
      : useStore.getState().totalPlayed === 0,
    today: start.toISOString(),
    stepsTaken: 0,
    timeTaken: 0,
    perfectGame: true,
    noMistakes: true,
    visitedEveryRoom: false,
    hiddenRoomFound: false,
    hiddenPuzzleSolved: false,
    superHiddenRoomFound: false,
    superHiddenPuzzleSolved: false,
    carefulStepper: false,
    activatedPortals: false,
    usedPortalWhileHoldingItem: false,
    greenThumb: false,
    plantKiller: false,
    foundWrongWarp: false,
    beatSmileRoom: false,
    beatGameWithArtifactsRemaining: false,
    foundCakeRoom: false,
    ateCake: false,
    badgesCollectedToday: null,
  });

  if (urlParams.has('s')) {
    useStore.getState().setHelpDialogOpen(false);
  }
  if (urlParams.has('demo')) {
    useStore.getState().setHelpDialogOpen(false);
    useStore.getState().setDemoMode(true);
  } else {
    useStore.getState().setDemoMode(false);
  }
  if (
    format(start, 'yyyy-MM-dd') === '2023-07-28' &&
    useStore.getState().totalPlayed > 0
  ) {
    useStore.getState().setThankYouDialogOpen(true);
  }

  game = new Phaser.Game({
    type: Phaser.WEBGL,
    backgroundColor: isEndlessMode() ? '#779090' : '#125555',
    width: window.innerWidth,
    height: window.innerHeight,
    scene: [Main, UI],
    pixelArt: true,
    seed: [format(start, 'yyyy-MM-dd')],
  });

  window.addEventListener('resize', () => {
    game?.scale.resize(window.innerWidth, window.innerHeight);
  });
}

export default game;
