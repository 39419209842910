import Phaser from 'phaser';
import game from '../index';
import { isMobile } from '../util';
import Main, { swipeRef } from './Main';
import useStore from '../react/useStore';

export default class UI extends Phaser.Scene {
  CONSTANT_INPUT_TIMEOUT = 200;

  CONSTANT_INPUT_INTERVAL = 200;

  up: Phaser.GameObjects.Sprite;
  down: Phaser.GameObjects.Sprite;
  left: Phaser.GameObjects.Sprite;
  right: Phaser.GameObjects.Sprite;

  constructor() {
    super({
      key: 'UI',
      active: true,
    });
  }

  preload() {
    this.load.spritesheet('up', '20220531up-Sheet.png', {
      frameWidth: 48,
      frameHeight: 48,
    });
    this.load.spritesheet('down', '20220531down-Sheet.png', {
      frameWidth: 48,
      frameHeight: 48,
    });
    this.load.spritesheet('left', '20220531left-Sheet.png', {
      frameWidth: 48,
      frameHeight: 48,
    });
    this.load.spritesheet('right', '20220531right-Sheet.png', {
      frameWidth: 48,
      frameHeight: 48,
    });
  }

  create(): void {
    if (isMobile() && !useStore.getState().demoMode) {
      const up = this.add.sprite(
        this.cameras.main.centerX,
        this.cameras.main.y + this.cameras.main.height - 400,
        'up',
        0,
      );
      up.setInteractive();
      up.setAlpha(0.7);
      up.setScale(3.5);
      let upInterval: number;
      up.on('pointerdown', () => {
        const mainScene = game!.scene.getScene('main') as Main;
        mainScene.movementManager.handleUp();
        upInterval = window.setInterval(() => {
          mainScene.movementManager.handleUp();
        }, this.CONSTANT_INPUT_INTERVAL);
        up.setTexture('up', 1);
      });
      up.on('pointerup', () => {
        clearInterval(upInterval);
        up.setTexture('up', 0);
      });
      up.setScrollFactor(0);

      const down = this.add.sprite(
        this.cameras.main.centerX,
        this.cameras.main.y + this.cameras.main.height - 150,
        'down',
      );
      down.setInteractive();
      down.setAlpha(0.7);
      down.setScale(3.5);
      let downInterval: number;
      down.on('pointerdown', () => {
        const mainScene = game!.scene.getScene('main') as Main;
        mainScene.movementManager.handleDown();
        down.setTexture('down', 1);

        downInterval = window.setInterval(() => {
          mainScene.movementManager.handleDown();
        }, this.CONSTANT_INPUT_INTERVAL);
      });
      down.on('pointerup', () => {
        down.setTexture('down', 0);
        clearInterval(downInterval);
      });

      down.setScrollFactor(0);

      const left = this.add.sprite(
        this.cameras.main.centerX - 175,
        this.cameras.main.y + this.cameras.main.height - 275,
        'left',
      );
      left.setInteractive();
      left.setAlpha(0.7);
      left.setScale(3.5);
      let leftInterval: number;
      left.on('pointerdown', () => {
        const mainScene = game!.scene.getScene('main') as Main;
        mainScene.movementManager.handleLeft();
        left.setTexture('left', 1);

        leftInterval = window.setInterval(() => {
          mainScene.movementManager.handleLeft();
        }, this.CONSTANT_INPUT_INTERVAL);
      });
      left.on('pointerup', () => {
        left.setTexture('left', 0);
        clearInterval(leftInterval);
      });

      left.setScrollFactor(0);

      const right = this.add.sprite(
        this.cameras.main.centerX + 175,
        this.cameras.main.y + this.cameras.main.height - 275,
        'right',
      );
      right.setInteractive();
      right.setAlpha(0.7);
      right.setScale(3.5);
      let rightInterval: number;
      right.on('pointerdown', () => {
        const mainScene = game!.scene.getScene('main') as Main;
        mainScene.movementManager.handleRight();
        right.setTexture('right', 1);

        rightInterval = window.setInterval(() => {
          mainScene.movementManager.handleRight();
        }, this.CONSTANT_INPUT_INTERVAL);
      });
      right.on('pointerup', () => {
        right.setTexture('right', 0);
        clearInterval(rightInterval);
      });

      this.input.on('pointerup', () => {
        up.setTexture('up', 0);
        down.setTexture('down', 0);
        left.setTexture('left', 0);
        right.setTexture('right', 0);
        clearInterval(upInterval);
        clearInterval(downInterval);
        clearInterval(leftInterval);
        clearInterval(rightInterval);
      });

      this.left = left;
      this.right = right;
      this.up = up;
      this.down = down;
    }
  }

  update() {
    if (swipeRef && swipeRef.isSwiped) {
      this.left?.disableInteractive();
      this.right?.disableInteractive();
      this.up?.disableInteractive();
      this.down?.disableInteractive();
    } else {
      this.left?.setInteractive();
      this.right?.setInteractive();
      this.up?.setInteractive();
      this.down?.setInteractive();
    }
  }
}
