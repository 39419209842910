import { format } from 'date-fns';
import Phaser, { Scene } from 'phaser';
import Artifact from './Artifact';
import Collectible from './Collectible';
import Dude from './Dude';
import Dungeon from './Dungeon';
import Key from './Key';
import useStore from './react/useStore';
import Room from './Room';
import Main from './scenes/Main';
import Seed from './Seed';
import { start } from './util';

declare global {
  interface Window {
    inputs: string[];
  }
}

export default class MovementManager {
  dude: Dude;

  dungeon: Dungeon;

  alphaDecrement = format(start, 'yyyy-MM-dd') === '2022-05-13' ? 1 : 0.025;

  constructor(dude: Dude, dungeon: Dungeon) {
    this.dude = dude;
    this.dungeon = dungeon;
    window.inputs = [];
  }

  resolveMovement(scene: Main) {
    this.resolveInputs(scene);

    let previousRoom;
    const roomsVisited = new Set();
    this.dude.sprite.on('move', (previousPosition: number[]) => {
      this.dude.fireWalkAnimation();
      window.ReactNativeWebView?.postMessage('vibrate', '*');

      this.updateRoomAlphas(scene);
      const currentRoom = this.determineCurrentRoom();

      this.resolveCurrentRoomBadges(currentRoom, roomsVisited);

      if (
        previousRoom &&
        previousRoom !== currentRoom &&
        currentRoom.roomBehavior?.onEnter
      ) {
        currentRoom.roomBehavior.onEnter(this.dude, this.dungeon);
      }

      previousRoom = currentRoom;

      const collectibles = this.dungeon.getCollectibles();

      this.handleCurrentItem(collectibles, currentRoom, previousPosition);

      this.handleLocks(scene);
    });
  }

  private handleLocks(scene: Main) {
    const closestLock = this.dungeon.locks.sort((a, b) => {
      const aDistance = Phaser.Math.Distance.Between(
        a.tilePosition[0],
        a.tilePosition[1],
        this.dude.tilePosition[0],
        this.dude.tilePosition[1],
      );
      const bDistance = Phaser.Math.Distance.Between(
        b.tilePosition[0],
        b.tilePosition[1],
        this.dude.tilePosition[0],
        this.dude.tilePosition[1],
      );
      return aDistance - bDistance;
    })[0];

    if (
      Phaser.Math.Distance.Between(
        this.dungeon.planter.tilePosition[0],
        this.dungeon.planter.tilePosition[1],
        this.dungeon.seed.tilePosition[0],
        this.dungeon.seed.tilePosition[1],
      ) <= 2 &&
      !this.dungeon.seed.planted
    ) {
      this.dungeon.seed.planted = true;
      this.dungeon.planter.plant(this.dude, scene);
      this.dungeon.seed.tilePosition = this.dungeon.planter.tilePosition;
      this.dude.currentItem = null;
      scene.tweens.add({
        targets: this.dungeon.seed.sprite,
        alpha: 0,
        duration: 300,
        onComplete: () => {
          this.dungeon.seed.sprite.destroy();
        },
      });
    }

    const cakeRoom = this.dungeon.rooms
      .flat()
      .find(room => room.roomTypeName === 'CAKE_ROOM');

    if (
      cakeRoom &&
      this.dungeon.fork &&
      Phaser.Math.Distance.Between(
        this.dungeon.cake.tilePosition[0],
        this.dungeon.cake.tilePosition[1],
        this.dungeon.fork.tilePosition[0],
        this.dungeon.fork.tilePosition[1],
      ) <= 2 &&
      !this.dungeon.fork.planted
    ) {
      this.dungeon.fork.planted = true;
      this.dungeon.fork.tilePosition = [
        cakeRoom.tilePosition[0] * Room.ROOM_SIZE + 3,
        cakeRoom.tilePosition[1] * Room.ROOM_SIZE + 3,
      ];
      this.dude.currentItem = null;
      scene.tweens.add({
        targets: this.dungeon.fork.sprite,
        alpha: 0,
        duration: 300,
        onComplete: () => {
          this.dungeon.fork!.sprite.destroy();
          this.dungeon.fork = null;
          useStore.getState().setAteCake(true);
        },
      });
      scene.tweens.add({
        targets: this.dungeon.cake.sprite,
        alpha: 0,
        duration: 300,
        onComplete: () => {
          this.dungeon.cake.sprite.destroy();
        },
      });
    }

    if (
      closestLock &&
      this.dude.currentItem &&
      this.dungeon.keys.includes(this.dude.currentItem as Key) &&
      this.dude.currentItem.sprite.tintTopLeft ===
        closestLock.sprite.tintTopLeft &&
      !(this.dude.currentItem as Key).destructionImminent &&
      Phaser.Math.Distance.Between(
        this.dude.tilePosition[0],
        this.dude.tilePosition[1],
        closestLock.tilePosition[0],
        closestLock.tilePosition[1],
      ) <= 2
    ) {
      const lock = this.dungeon.locks.find(l =>
        [l.sprite, l.adjacentSprite].includes(closestLock.sprite),
      );
      if (lock) {
        this.dude.currentItem.tilePosition = closestLock.tilePosition;
        (this.dude.currentItem as Key).destructionImminent = true;
        lock.destructionImminent = true;
        this.setMapTile(lock.tilePosition[0], lock.tilePosition[1], 2);
        this.setMapTile(
          lock.adjacentTilePosition[0],
          lock.adjacentTilePosition[1],
          2,
        );
        const { currentItem } = this.dude;
        scene.time.addEvent({
          delay: 300,
          callback: () => {
            currentItem?.sprite.destroy();
            lock.unlock(this.dungeon);
            this.dungeon.keys.splice(
              this.dungeon.keys.indexOf(currentItem as Key),
              1,
            );
            this.dude.currentItem = null;
          },
        });
        scene.add.tween({
          targets: lock.sprite,
          duration: 300,
          alpha: 0,
        });
        scene.add.tween({
          targets: lock.adjacentSprite,
          duration: 300,
          alpha: 0,
        });
        scene.add.tween({
          targets: this.dude.currentItem.sprite,
          duration: 300,
          alpha: 0,
        });
      }
    }
  }

  private resolveInputs(scene: Main) {
    let intervalA;
    scene.input.keyboard
      .addKey('A', true)
      .on('down', () => {
        window.inputs.push('left');
        if (this.dude.done) {
          return;
        }
        this.handleLeft();
        intervalA = window.setInterval(() => {
          this.handleLeft();
        }, 200);
      })
      .on('up', () => {
        window.clearInterval(intervalA);
      });
    let intervalLEFT;
    scene.input.keyboard
      .addKey('LEFT', true)
      .on('down', () => {
        window.inputs.push('left');
        if (this.dude.done) {
          return;
        }
        this.handleLeft();
        intervalLEFT = window.setInterval(() => {
          this.handleLeft();
        }, 200);
      })
      .on('up', () => {
        window.clearInterval(intervalLEFT);
      });

    let intervalD;
    scene.input.keyboard
      .addKey('D', true)
      .on('down', () => {
        window.inputs.push('right');
        if (this.dude.done) {
          return;
        }
        this.handleRight();
        intervalD = window.setInterval(() => {
          this.handleRight();
        }, 200);
      })
      .on('up', () => {
        window.clearInterval(intervalD);
      });
    let intervalRIGHT;
    scene.input.keyboard
      .addKey('RIGHT', true)
      .on('down', () => {
        window.inputs.push('right');
        if (this.dude.done) {
          return;
        }
        this.handleRight();
        intervalRIGHT = window.setInterval(() => {
          this.handleRight();
        }, 200);
      })
      .on('up', () => {
        window.clearInterval(intervalRIGHT);
      });

    let intervalW;
    scene.input.keyboard
      .addKey('W', true)
      .on('down', () => {
        window.inputs.push('up');
        if (this.dude.done) {
          return;
        }
        this.handleUp();
        intervalW = window.setInterval(() => {
          this.handleUp();
        }, 200);
      })
      .on('up', () => {
        window.clearInterval(intervalW);
      });
    let intervalUP;
    scene.input.keyboard
      .addKey('UP', true)
      .on('down', () => {
        window.inputs.push('up');
        if (this.dude.done) {
          return;
        }
        this.handleUp();
        intervalUP = window.setInterval(() => {
          this.handleUp();
        }, 200);
      })
      .on('up', () => {
        window.clearInterval(intervalUP);
      });

    let intervalS;
    scene.input.keyboard
      .addKey('S', true)
      .on('down', () => {
        window.inputs.push('down');
        if (this.dude.done) {
          return;
        }
        this.handleDown();
        intervalS = window.setInterval(() => {
          this.handleDown();
        }, 200);
      })
      .on('up', () => {
        window.clearInterval(intervalS);
      });
    let intervalDown;
    scene.input.keyboard
      .addKey('Down', true)
      .on('down', () => {
        window.inputs.push('down');
        if (this.dude.done) {
          return;
        }
        this.handleDown();
        intervalDown = window.setInterval(() => {
          this.handleDown();
        }, 200);
      })
      .on('up', () => {
        window.clearInterval(intervalDown);
      });
  }

  private handleCurrentItem(
    collectibles: Collectible[],
    currentRoom: Room,
    previousPosition: number[],
  ) {
    for (const collectible of collectibles) {
      if (
        this.dude.tilePosition[0] === collectible.tilePosition[0] &&
        this.dude.tilePosition[1] === collectible.tilePosition[1] &&
        ((this.dungeon.artifacts.includes(collectible as Artifact) &&
          currentRoom.roomTypeName !== 'ARTIFACT_ROOM' &&
          currentRoom.roomTypeName !== 'SMILE' &&
          !(collectible as Artifact).collected) ||
          this.dungeon.keys.includes(collectible as Key) ||
          (collectible === this.dungeon.seed &&
            !(collectible as Seed).planted) ||
          collectible === this.dungeon.fork)
      ) {
        if (this.dude.currentItem && this.dude.currentItem !== collectible) {
          useStore.getState().setPerfectGame(false);
          const [x, y] = [
            this.dude.currentItem.tilePosition[0] -
              Math.floor(this.dude.tilePosition[0] / Room.ROOM_SIZE) *
                Room.ROOM_SIZE,
            this.dude.currentItem.tilePosition[1] -
              Math.floor(this.dude.tilePosition[1] / Room.ROOM_SIZE) *
                Room.ROOM_SIZE,
          ];
          if (
            (x === 3 && y === 6) ||
            (x === 6 && y === 3) ||
            (x === 0 && y === 3) ||
            (x === 3 && y === 0)
          ) {
            const randomPosition = currentRoom.pickRandomPosition();
            this.dude.currentItem.tilePosition = [
              Math.floor(this.dude.tilePosition[0] / Room.ROOM_SIZE) *
                Room.ROOM_SIZE +
                randomPosition[0],
              Math.floor(this.dude.tilePosition[1] / Room.ROOM_SIZE) *
                Room.ROOM_SIZE +
                randomPosition[1],
            ];
          }
        }
        this.dude.currentItem = collectible;
        if (
          (currentRoom.roomTypeName === 'ARTIFACT_ROOM' ||
            currentRoom.roomTypeName === 'SMILE') &&
          this.dungeon.artifacts.includes(collectible as Artifact)
        ) {
          (collectible as Artifact).collected = true;
          (collectible as Artifact).sprite.play(
            (collectible as Artifact).anim.key,
          );
        }
      }
    }

    if (
      this.dude.currentItem &&
      !(this.dude.currentItem as Key).destructionImminent
    ) {
      this.dude.currentItem.tilePosition = previousPosition;
    }
  }

  private resolveCurrentRoomBadges(
    currentRoom: Room,
    roomsVisited: Set<unknown>,
  ) {
    if (currentRoom.roomTypeName === 'HIDDEN_ROOM') {
      useStore.getState().setHiddenRoomFound(true);
    }
    if (currentRoom.roomTypeName === 'SUPER_HIDDEN_ROOM') {
      useStore.getState().setSuperHiddenRoomFound(true);
    }

    roomsVisited.add(currentRoom.id);

    if (
      Array.from(roomsVisited).length ===
      this.dungeon.rooms.flat().filter(Boolean).length
    ) {
      useStore.getState().setVisitedEveryRoom(true);
    }
  }

  private determineCurrentRoom() {
    return this.dungeon.rooms[
      Math.floor(this.dude.tilePosition[0] / Room.ROOM_SIZE)
    ]?.[Math.floor(this.dude.tilePosition[1] / Room.ROOM_SIZE)];
  }

  private determineCurrentRoomForItem(collectible: Collectible) {
    return this.dungeon.rooms[
      Math.floor(collectible.tilePosition[0] / Room.ROOM_SIZE)
    ]?.[Math.floor(collectible.tilePosition[1] / Room.ROOM_SIZE)];
  }

  handleUp() {
    this.dude.explode();
    this.dude.sprite.emit('moveAttempt', 'up', this.dude.tilePosition);
    if (
      ![0, 3].includes(
        this.getMapTile(
          this.dude.tilePosition[0] - 1,
          this.dude.tilePosition[1],
        ),
      )
    ) {
      const previousPosition = [...this.dude.tilePosition];
      this.dude.tilePosition[0] -= 1;
      this.dude.sprite.emit('move', previousPosition, this.dude.tilePosition);
      useStore.getState().incrStepsTaken();
    }
  }

  handleDown() {
    this.dude.explode();
    this.dude.sprite.emit('moveAttempt', 'down', this.dude.tilePosition);
    if (
      ![0, 3].includes(
        this.getMapTile(
          this.dude.tilePosition[0] + 1,
          this.dude.tilePosition[1],
        ),
      )
    ) {
      const previousPosition = [...this.dude.tilePosition];
      this.dude.tilePosition[0] += 1;
      this.dude.sprite.emit('move', previousPosition, this.dude.tilePosition);
      useStore.getState().incrStepsTaken();
    }
  }

  handleLeft() {
    this.dude.sprite.flipX = true;
    this.dude.explode();
    this.dude.sprite.emit('moveAttempt', 'left', this.dude.tilePosition);
    if (
      ![0, 3].includes(
        this.getMapTile(
          this.dude.tilePosition[0],
          this.dude.tilePosition[1] - 1,
        ),
      )
    ) {
      const previousPosition = [...this.dude.tilePosition];
      this.dude.tilePosition[1] -= 1;
      this.dude.sprite.emit('move', previousPosition, this.dude.tilePosition);
      useStore.getState().incrStepsTaken();
    }
  }

  handleRight() {
    this.dude.sprite.flipX = false;
    this.dude.explode();
    this.dude.sprite.emit('moveAttempt', 'right', this.dude.tilePosition);
    if (
      ![0, 3].includes(
        this.getMapTile(
          this.dude.tilePosition[0],
          this.dude.tilePosition[1] + 1,
        ),
      )
    ) {
      const previousPosition = [...this.dude.tilePosition];
      this.dude.tilePosition[1] += 1;
      this.dude.sprite.emit('move', previousPosition, this.dude.tilePosition);
      useStore.getState().incrStepsTaken();
    }
  }

  updateRoomAlphas(scene: Scene) {
    const { tilePosition } = this.dude;
    const currentRoom =
      this.dungeon.rooms[Math.floor(tilePosition[0] / Room.ROOM_SIZE)]?.[
        Math.floor(tilePosition[1] / Room.ROOM_SIZE)
      ];

    // console.log(currentRoom.tileNumbers);
    for (let i = 0; i < Dungeon.MAP_SIZE; i += 1) {
      for (let j = 0; j < Dungeon.MAP_SIZE; j += 1) {
        if (!this.dungeon.rooms[i]?.[j]) {
          continue;
        }
        this.dungeon.rooms[i][j].tiles.flat().forEach(tile => {
          if (this.dungeon.rooms[i][j] === currentRoom) {
            scene.tweens.add({
              targets: tile,
              alpha: tile.getData('targetAlpha'),
              duration: 50,
            });
          } else if (
            this.dungeon.rooms[i][j] !== currentRoom &&
            tile.alpha > 0
          ) {
            scene.tweens.add({
              targets: tile,
              alpha: tile.alpha - this.alphaDecrement,
              duration: 50,
            });
          }
        });
      }
    }

    for (const key of this.dungeon.keys) {
      if (
        key === this.dude.currentItem ||
        this.determineCurrentRoomForItem(key) === currentRoom
      ) {
        scene.tweens.add({
          targets: key.sprite,
          alpha: 1,
          duration: 50,
        });
      } else {
        scene.tweens.add({
          targets: key.sprite,
          alpha: key.sprite.alpha - this.alphaDecrement,
          duration: 50,
        });
      }
    }

    if (
      this.dungeon.seed === this.dude.currentItem ||
      (!this.dungeon.seed.planted &&
        this.determineCurrentRoomForItem(this.dungeon.seed) === currentRoom)
    ) {
      scene.tweens.add({
        targets: this.dungeon.seed.sprite,
        alpha: 1,
        duration: 50,
      });
    } else {
      scene.tweens.add({
        targets: this.dungeon.seed.sprite,
        alpha: this.dungeon.seed.sprite.alpha - this.alphaDecrement,
        duration: 50,
      });
    }

    if (this.dungeon.fork) {
      if (
        this.dungeon.fork === this.dude.currentItem ||
        (!this.dungeon.fork.planted &&
          this.determineCurrentRoomForItem(this.dungeon.fork) === currentRoom)
      ) {
        scene.tweens.add({
          targets: this.dungeon.fork.sprite,
          alpha: 1,
          duration: 50,
        });
      } else {
        scene.tweens.add({
          targets: this.dungeon.fork.sprite,
          alpha: this.dungeon.fork.sprite.alpha - this.alphaDecrement,
          duration: 50,
        });
      }
    }

    for (const artifact of this.dungeon.artifacts) {
      if (
        artifact === this.dude.currentItem ||
        this.determineCurrentRoomForItem(artifact) === currentRoom
      ) {
        scene.tweens.add({
          targets: artifact.sprite,
          alpha: 1,
          duration: 50,
        });
      } else {
        scene.tweens.add({
          targets: artifact.sprite,
          alpha: artifact.sprite.alpha - this.alphaDecrement,
          duration: 50,
        });
      }
    }

    for (const lock of [...this.dungeon.locks, this.dungeon.tokenLock]) {
      if (lock.destructionImminent) {
        continue;
      }
      if (
        this.dungeon.rooms[
          Math.floor(lock.adjacentTilePosition[0] / Room.ROOM_SIZE)
        ]?.[Math.floor(lock.adjacentTilePosition[1] / Room.ROOM_SIZE)] ===
        currentRoom
      ) {
        const lockSpriteToTween = lock.sprite;
        scene.tweens.add({
          targets: lockSpriteToTween,
          alpha: 1,
          duration: 50,
        });
        if (lock.text) {
          scene.tweens.add({
            targets: lock.text,
            alpha: 1,
            duration: 50,
          });
        }
      } else {
        scene.tweens.add({
          targets: lock.sprite,
          alpha: lock.sprite.alpha - this.alphaDecrement,
          duration: 50,
        });
        scene.tweens.add({
          targets: lock.adjacentSprite,
          alpha: lock.adjacentSprite.alpha - this.alphaDecrement,
          duration: 50,
        });
        if (lock.text) {
          scene.tweens.add({
            targets: lock.text,
            alpha: lock.text.alpha - this.alphaDecrement,
            duration: 50,
          });
        }
      }
      if (this.determineCurrentRoomForItem(lock) === currentRoom) {
        const lockSpriteToTween = lock.adjacentSprite;
        scene.tweens.add({
          targets: lockSpriteToTween,
          alpha: 1,
          duration: 50,
        });
        if (lock.text) {
          scene.tweens.add({
            targets: lock.text,
            alpha: 1,
            duration: 50,
          });
        }
      } else {
        scene.tweens.add({
          targets: lock.adjacentSprite,
          alpha: lock.adjacentSprite.alpha - this.alphaDecrement,
          duration: 50,
        });
        if (lock.text) {
          scene.tweens.add({
            targets: lock.text,
            alpha: lock.text.alpha - this.alphaDecrement,
            duration: 50,
          });
        }
      }
    }
  }

  private getMapTile(row: number, col: number): number {
    const room =
      this.dungeon.rooms[Math.floor(row / Room.ROOM_SIZE)]?.[
        Math.floor(col / Room.ROOM_SIZE)
      ];
    if (!room || !room.roomType) {
      return 0;
    }
    return room.roomType[row % Room.ROOM_SIZE][col % Room.ROOM_SIZE];
  }

  setMapTile(row: number, col: number, value: number): void {
    const room =
      this.dungeon.rooms[Math.floor(row / Room.ROOM_SIZE)]?.[
        Math.floor(col / Room.ROOM_SIZE)
      ];
    if (!room || !room.roomType) {
      return;
    }
    room.roomType[row % Room.ROOM_SIZE][col % Room.ROOM_SIZE] = value;
  }
}
