/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react';
import {
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Portal,
  Typography,
} from '@mui/material';
import {
  Menu,
  QuestionMark,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import useStore from './useStore';
import { determineStepsTaken, determineTimeTaken } from './CompletedDialog';
import { getMode, isEndlessMode, isMobile, setMode } from '../util';
import game from '..';

const Options: FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const showHUD = useStore(store => store.showHUD);
  const setShowHUD = useStore(store => store.setShowHUD);
  const setHelpDialogOpen = useStore(store => store.setHelpDialogOpen);
  useStore(store => store.stepsTaken);
  useStore(store => store.timeTaken);
  const endlessModePurchased = useStore(store => store.endlessModePurchased);

  return (
    <Portal>
      {showHUD && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            padding: 10,
            fontSize: isMobile() ? 46 : 24,
          }}
        >
          {determineStepsTaken()} {determineTimeTaken()}
        </div>
      )}
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isEndlessMode() && (
          <Typography
            component="span"
            style={{
              color: 'white',
              fontSize: isMobile() ? 46 : 30,
              marginRight: 12,
            }}
          >
            ENDLESS MODE
          </Typography>
        )}
        <IconButton
          onClick={() => {
            const main = game?.scene.getScene('main');
            main!.cameras.main.zoom *= 1.1;
          }}
        >
          <ZoomIn />
        </IconButton>
        <IconButton
          onClick={() => {
            const main = game?.scene.getScene('main');
            main!.cameras.main.zoom /= 1.1;
          }}
        >
          <ZoomOut />
        </IconButton>
        <IconButton onClick={() => setHelpDialogOpen(true)}>
          <QuestionMark />
        </IconButton>
        <IconButton onClick={() => setDrawerOpen(true)}>
          <Menu />
        </IconButton>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ListItem>
            <ListItemText
              primary={`${determineStepsTaken()}\n${determineTimeTaken()}`}
            />
          </ListItem>
          <MenuItem>
            <ListItemText
              primary="Toggle HUD"
              onClick={() => setShowHUD(!showHUD)}
            />
          </MenuItem>
          <MenuItem onClick={() => window.open('https://threads.net/hi.im.vijay')}>
            <ListItemIcon>
              <img src="./Threads.svg" alt="Threads" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Feedback or Suggestions?"
              secondary="Thread the developer"
            />
          </MenuItem>
          {(isEndlessMode() || endlessModePurchased) && (
            <MenuItem
              onClick={() =>
                setMode(getMode() === 'endless' ? 'daily' : 'endless')
              }
            >
              <ListItemText
                primary={
                  getMode() === 'daily'
                    ? 'Switch to endless mode'
                    : 'Switch to daily mode'
                }
              />
            </MenuItem>
          )}
        </Drawer>
      </div>
    </Portal>
  );
};

export default Options;
