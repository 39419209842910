import { format } from 'date-fns';
import Artifact from './Artifact';
import Dude from './Dude';
import Dungeon from './Dungeon';
import Exit from './Exit';
import Key from './Key';
import Lock from './Lock';
import Room, { DoorLocations } from './Room';
import RoomGenerator from './RoomGenerator';
import { wrongWarpRandom, shuffleArray, start } from './util';
import PuzzleGenerator from './PuzzleGenerator';
import Main from './scenes/Main';
import HiddenRoomPuzzle from './puzzles/HiddenRoomPuzzle';
import SlidePuzzle from './puzzles/SlidePuzzle';
import Lights from './puzzles/Lights';
import QrCodePuzzle from './puzzles/QrCodePuzzle';
import Warp from './Warp';
import WarpButton from './WarpButton';
import Planter from './Planter';
import Seed from './Seed';
import useStore from './react/useStore';
import Cake from './Cake';

export default class DungeonGenerator {
  dungeon: Dungeon;

  scene: Main;

  colors = [0x779cff, 0x18f752, 0xf91876];

  constructor(dungeon: Dungeon, scene: Main) {
    this.dungeon = dungeon;
    this.scene = scene;
  }

  generate(): void {
    const currentRoom = this.addRoom(
      [Dungeon.MIDPOINT, Dungeon.MIDPOINT],
      RoomGenerator.ROOM_TYPES.DEFAULT,
    );


    this.generateDoorsAndMap();
    if (useStore.getState().demoMode) {
      currentRoom.tiles.flat().forEach(tile => {
        tile.alpha = 1;
      });
      return;
    }

    let limit = 3;
    this.generateDrunkenWalkDungeon(
      [Dungeon.MIDPOINT, Dungeon.MIDPOINT],
      limit,
    );
    limit += 3;
    this.generateDrunkenWalkDungeon(
      [Dungeon.MIDPOINT, Dungeon.MIDPOINT],
      limit,
    );
    limit += 5;

    const randomRoomPosition = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .map(room => room.tilePosition);

    shuffleArray(randomRoomPosition);

    this.generateDrunkenWalkDungeon(
      [randomRoomPosition[0][0], randomRoomPosition[0][1]],
      limit,
    );
    limit += 3;

    shuffleArray(randomRoomPosition);

    this.generateDrunkenWalkDungeon(
      [randomRoomPosition[0][0], randomRoomPosition[0][1]],
      limit,
    );
    limit += 3;

    this.generateDoorsAndMap();

    const oneDoorRooms = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(
        room => room.doors.length === 1 && room.roomTypeName !== 'DEFAULT',
      );

    if (oneDoorRooms.length < 3) {
      this.dungeon.rooms.flat().forEach(room => room.destroy());
      this.dungeon.rooms = [];
      this.generate();
      return;
    }

    shuffleArray(oneDoorRooms);
    const puzzleRooms: Room[] = [];
    oneDoorRooms.slice(0, 3).forEach(row => {
      puzzleRooms.push(
        this.addRoom(row.tilePosition, RoomGenerator.ROOM_TYPES.PUZZLE_ROOM),
      );
    });
    const injectPuzzleRooms = (room: Room) => {
      const directions = (
        ['top', 'bottom', 'left', 'right'] as DoorLocations[]
      ).filter(direction => !room.doors.includes(direction));
      shuffleArray(directions);
      const direction = directions[0];
      let tilePosition;
      switch (direction) {
        case 'top':
          tilePosition = [room.tilePosition[0] - 1, room.tilePosition[1]];
          break;
        case 'bottom':
          tilePosition = [room.tilePosition[0] + 1, room.tilePosition[1]];
          break;
        case 'left':
          tilePosition = [room.tilePosition[0], room.tilePosition[1] - 1];
          break;
        case 'right':
          tilePosition = [room.tilePosition[0], room.tilePosition[1] + 1];
          break;

        default:
          break;
      }
      if (!this.dungeon.rooms[tilePosition[0]]?.[tilePosition[1]]) {
        puzzleRooms.push(
          this.addRoom(tilePosition, RoomGenerator.ROOM_TYPES.PUZZLE_ROOM),
        );
      }
    };

    oneDoorRooms.slice(0, 3 - puzzleRooms.length).forEach(injectPuzzleRooms);
    this.generateDoorsAndMap();

    if (puzzleRooms.length < 3) {
      this.generateDrunkenWalkDungeon(
        [Dungeon.MIDPOINT, Dungeon.MIDPOINT],
        limit,
      );
      limit += 3;
    }

    if (puzzleRooms.length < 3) {
      this.generateDrunkenWalkDungeon(
        [Dungeon.MIDPOINT, Dungeon.MIDPOINT],
        limit,
      );
      limit += 3;
    }

    this.generateDoorsAndMap();

    const twoDoorRooms = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(room => room.doors.length === 2);

    twoDoorRooms.slice(0, 3 - puzzleRooms.length).forEach(injectPuzzleRooms);
    this.generateDoorsAndMap();

    const threeDoorRooms = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(room => room.doors.length === 3);

    threeDoorRooms.slice(0, 3 - puzzleRooms.length).forEach(injectPuzzleRooms);
    this.generateDoorsAndMap();

    const injectHiddenRoom = (room: Room) => {
      const directions = (
        ['top', 'bottom', 'left', 'right'] as DoorLocations[]
      ).filter(direction => !room.doors.includes(direction));
      shuffleArray(directions);
      const direction = directions[0];
      let tilePosition;
      switch (direction) {
        case 'top':
          tilePosition = [room.tilePosition[0] - 1, room.tilePosition[1]];
          break;
        case 'bottom':
          tilePosition = [room.tilePosition[0] + 1, room.tilePosition[1]];
          break;
        case 'left':
          tilePosition = [room.tilePosition[0], room.tilePosition[1] - 1];
          break;
        case 'right':
          tilePosition = [room.tilePosition[0], room.tilePosition[1] + 1];
          break;

        default:
          break;
      }
      if (!this.dungeon.rooms[tilePosition[0]]?.[tilePosition[1]]) {
        const hiddenRoom = this.addRoom(
          tilePosition,
          RoomGenerator.ROOM_TYPES.HIDDEN_ROOM,
        );
        new HiddenRoomPuzzle(hiddenRoom, this.dungeon, this.scene).generate();
        const warpButtonPosition = hiddenRoom.pickRandomCorner();
        const warpButton = new WarpButton();
        warpButton.addToScene(
          this.scene,
          [
            hiddenRoom.tilePosition[0] * Room.ROOM_SIZE + warpButtonPosition[0],
            hiddenRoom.tilePosition[1] * Room.ROOM_SIZE + warpButtonPosition[1],
          ],
          hiddenRoom,
        );
        warpButton.onPress = () => {
          this.dungeon.warpTiles.forEach(warpTile => {
            warpTile.active = true;
            warpTile.sprite.play('warp-idle');
          });
          // eslint-disable-next-line no-use-before-define
          wrongWarpWarpTile.active = true;
        };
      }
    };

    const injectSuperHiddenRoom = (room: Room) => {
      const directions = (
        ['top', 'bottom', 'left', 'right'] as DoorLocations[]
      ).filter(direction => !room.doors.includes(direction));
      shuffleArray(directions);
      const direction = directions[0];
      let tilePosition;
      switch (direction) {
        case 'top':
          tilePosition = [room.tilePosition[0] - 1, room.tilePosition[1]];
          break;
        case 'bottom':
          tilePosition = [room.tilePosition[0] + 1, room.tilePosition[1]];
          break;
        case 'left':
          tilePosition = [room.tilePosition[0], room.tilePosition[1] - 1];
          break;
        case 'right':
          tilePosition = [room.tilePosition[0], room.tilePosition[1] + 1];
          break;

        default:
          break;
      }
      if (!this.dungeon.rooms[tilePosition[0]]?.[tilePosition[1]]) {
        const superHiddenRoom = this.addRoom(
          tilePosition,
          RoomGenerator.ROOM_TYPES.SUPER_HIDDEN_ROOM,
        );
        const superHiddenRoomPuzzleTypes = [QrCodePuzzle, SlidePuzzle, Lights];
        shuffleArray(superHiddenRoomPuzzleTypes);
        new superHiddenRoomPuzzleTypes[0](
          superHiddenRoom,
          this.dungeon,
          this.scene,
        ).generate();
        let superHiddenRoomFound = false;
        let superHiddenRoomEntered = false;
        const superHiddenRoomDestroyed = false;
        this.scene.dude.sprite.on('move', () => {
          if (superHiddenRoomDestroyed) {
            return;
          }
          const currRoom =
            this.dungeon.rooms[
              Math.floor(this.scene.dude.tilePosition[0] / Room.ROOM_SIZE)
            ]?.[Math.floor(this.scene.dude.tilePosition[1] / Room.ROOM_SIZE)];
          const currentTilePosition = currRoom.tilePosition;
          // check if the current room is or is adjacent to the super hidden room
          if (
            !superHiddenRoomEntered &&
            ((currentTilePosition[0] - 1 === superHiddenRoom.tilePosition[0] &&
              currentTilePosition[1] === superHiddenRoom.tilePosition[1]) ||
              (currentTilePosition[0] + 1 === superHiddenRoom.tilePosition[0] &&
                currentTilePosition[1] === superHiddenRoom.tilePosition[1]) ||
              (currentTilePosition[0] === superHiddenRoom.tilePosition[0] &&
                currentTilePosition[1] - 1 ===
                  superHiddenRoom.tilePosition[1]) ||
              (currentTilePosition[0] === superHiddenRoom.tilePosition[0] &&
                currentTilePosition[1] + 1 === superHiddenRoom.tilePosition[1]))
          ) {
            superHiddenRoomFound = true;
          } else if (
            currentTilePosition[0] === superHiddenRoom.tilePosition[0] &&
            currentTilePosition[1] === superHiddenRoom.tilePosition[1]
          ) {
            superHiddenRoomEntered = true;
          } else if (superHiddenRoomFound) {
            // superHiddenRoomDestroyed = true;
            // superHiddenRoom.destroy();
            // delete this.dungeon.rooms[superHiddenRoom.tilePosition[0]][
            //   superHiddenRoom.tilePosition[1]
            // ];
            // this.generateDoorsAndMap();
          }
        });
      }
    };

    const injectCakeRoom = (room: Room) => {
      const directions = (
        ['top', 'bottom', 'left', 'right'] as DoorLocations[]
      ).filter(direction => !room.doors.includes(direction));
      shuffleArray(directions);
      const direction = directions[0];
      let tilePosition;
      switch (direction) {
        case 'top':
          tilePosition = [room.tilePosition[0] - 1, room.tilePosition[1]];
          break;
        case 'bottom':
          tilePosition = [room.tilePosition[0] + 1, room.tilePosition[1]];
          break;
        case 'left':
          tilePosition = [room.tilePosition[0], room.tilePosition[1] - 1];
          break;
        case 'right':
          tilePosition = [room.tilePosition[0], room.tilePosition[1] + 1];
          break;

        default:
          break;
      }
      if (!this.dungeon.rooms[tilePosition[0]]?.[tilePosition[1]]) {
        const superHiddenRoom = this.addRoom(
          tilePosition,
          RoomGenerator.ROOM_TYPES.CAKE_ROOM,
        );
        const cake = new Cake();
        this.dungeon.cake = cake;
        cake.addToScene(this.scene, [
          superHiddenRoom.tilePosition[0] * Room.ROOM_SIZE + 3,
          superHiddenRoom.tilePosition[1] * Room.ROOM_SIZE + 3,
        ]);
        cake.sprite.setDepth(1);
        let superHiddenRoomFound = false;
        let superHiddenRoomEntered = false;
        let superHiddenRoomDestroyed = false;
        this.scene.dude.sprite.on('move', () => {
          if (superHiddenRoomDestroyed) {
            return;
          }
          cake.sprite.alpha = superHiddenRoom.tiles.flat()[0].alpha;
          const currRoom =
            this.dungeon.rooms[
              Math.floor(this.scene.dude.tilePosition[0] / Room.ROOM_SIZE)
            ]?.[Math.floor(this.scene.dude.tilePosition[1] / Room.ROOM_SIZE)];
          const currentTilePosition = currRoom.tilePosition;
          // check if the current room is or is adjacent to the super hidden room
          if (
            !superHiddenRoomEntered &&
            ((currentTilePosition[0] - 1 === superHiddenRoom.tilePosition[0] &&
              currentTilePosition[1] === superHiddenRoom.tilePosition[1]) ||
              (currentTilePosition[0] + 1 === superHiddenRoom.tilePosition[0] &&
                currentTilePosition[1] === superHiddenRoom.tilePosition[1]) ||
              (currentTilePosition[0] === superHiddenRoom.tilePosition[0] &&
                currentTilePosition[1] - 1 ===
                  superHiddenRoom.tilePosition[1]) ||
              (currentTilePosition[0] === superHiddenRoom.tilePosition[0] &&
                currentTilePosition[1] + 1 === superHiddenRoom.tilePosition[1]))
          ) {
            superHiddenRoomFound = true;
          } else if (
            currentTilePosition[0] === superHiddenRoom.tilePosition[0] &&
            currentTilePosition[1] === superHiddenRoom.tilePosition[1]
          ) {
            superHiddenRoomEntered = true;
            useStore.getState().setFoundCakeRoom(true);
          } else if (superHiddenRoomFound && !superHiddenRoomEntered) {
            superHiddenRoomDestroyed = true;
            cake.sprite.destroy();
            superHiddenRoom.destroy();
            delete this.dungeon.rooms[superHiddenRoom.tilePosition[0]][
              superHiddenRoom.tilePosition[1]
            ];
            this.generateDoorsAndMap();
          }
        });
      }
    };

    const injectTokenRoom = (room: Room): Room | null => {
      const directions = (
        ['top', 'bottom', 'left', 'right'] as DoorLocations[]
      ).filter(direction => !room.doors.includes(direction));
      shuffleArray(directions);
      const direction = directions[0];
      let tilePosition;
      switch (direction) {
        case 'top':
          tilePosition = [room.tilePosition[0] - 1, room.tilePosition[1]];
          break;
        case 'bottom':
          tilePosition = [room.tilePosition[0] + 1, room.tilePosition[1]];
          break;
        case 'left':
          tilePosition = [room.tilePosition[0], room.tilePosition[1] - 1];
          break;
        case 'right':
          tilePosition = [room.tilePosition[0], room.tilePosition[1] + 1];
          break;

        default:
          break;
      }
      if (!this.dungeon.rooms[tilePosition[0]]?.[tilePosition[1]]) {
        return this.addRoom(tilePosition, RoomGenerator.ROOM_TYPES.TOKEN_ROOM);
      }

      return null;
    };

    const roomsToGridPosition = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(
        room =>
          room.roomTypeName !== 'DEFAULT' &&
          room.roomTypeName !== 'PUZZLE_ROOM',
      )
      .map(room => room.tilePosition);

    shuffleArray(roomsToGridPosition);

    this.generateDoorsAndMap();

    const room = this.addRoom(
      roomsToGridPosition[0],
      RoomGenerator.ROOM_TYPES.ARTIFACT_ROOM,
    );
    room.roomTypeName = 'ARTIFACT_ROOM';
    this.generateDoorsAndMap();

    const artifactPositions = [
      [2, 3],
      [4, 2],
      [4, 4],
    ];

    const wrongWarpArtifactPositions = [[2, 2]];

    if (process.env.NODE_ENV === 'development') {
      this.dungeon.exit = new Exit();
      this.dungeon.exit.addToScene(this.scene, [
        Math.floor(this.scene.dude.tilePosition[0] / Room.ROOM_SIZE) *
          Room.ROOM_SIZE +
          1,
        Math.floor(this.scene.dude.tilePosition[1] / Room.ROOM_SIZE) *
          Room.ROOM_SIZE +
          3,
      ]);
    }

    room.roomBehavior = {
      onEnter: (dude: Dude, dungeon: Dungeon) => {
        if (
          dude.currentItem &&
          dungeon.artifacts.includes(dude.currentItem as Artifact)
        ) {
          const position = artifactPositions.pop();
          dude.currentItem.tilePosition = [
            Math.floor(dude.tilePosition[0] / Room.ROOM_SIZE) * Room.ROOM_SIZE +
              position![0],
            Math.floor(dude.tilePosition[1] / Room.ROOM_SIZE) * Room.ROOM_SIZE +
              position![1],
          ];
          (dude.currentItem as Artifact).collected = true;
          (dude.currentItem as Artifact).sprite.play(
            (dude.currentItem as Artifact).anim.key,
          );
          dude.currentItem = null;
          this.scene.time.addEvent({
            delay: 200,
            callback: () => {
              this.scene.cameras.main.shake(240, 0.0003);
            },
          });
          if (artifactPositions.length === 0) {
            dungeon.exit = new Exit();
            dungeon.exit.addToScene(this.scene, [
              Math.floor(dude.tilePosition[0] / Room.ROOM_SIZE) *
                Room.ROOM_SIZE +
                3,
              Math.floor(dude.tilePosition[1] / Room.ROOM_SIZE) *
                Room.ROOM_SIZE +
                3,
            ]);
          }
        }
      },
    };

    puzzleRooms
      .sort((room1, room2) => room1.doors.length - room2.doors.length)
      .forEach((puzzleRoom, index) => {
        const lock = new Lock();
        if (process.env.NODE_ENV !== 'development') {
          lock.addToScene(
            this.scene,
            puzzleRoom,
            this.dungeon.rooms,
            this.dungeon.locks,
          );
          this.dungeon.locks[index] = lock;
          lock.setTint(this.colors[index]);
        }
      });

    puzzleRooms.forEach(puzzleRoom => {
      new PuzzleGenerator(puzzleRoom, this.dungeon, this.scene).generate();
    });

    this.generateDoorsAndMap();
    if (process.env.NODE_ENV !== 'development') {
      this.generateKeys();
    }
    currentRoom.tiles.flat().forEach(tile => {
      this.scene.tweens.add({
        targets: tile,
        alpha: 1,
        duration: 50,
      });
    });

    let roomsWithOpening = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(r => r.doors.length === 1);

    shuffleArray(roomsWithOpening);

    injectHiddenRoom(roomsWithOpening[0]);

    this.generateDoorsAndMap();

    roomsWithOpening = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(r => r.doors.length === 1);

    shuffleArray(roomsWithOpening);

    if (format(start, 'yyyy-MM-dd') !== '2022-05-13') {
      injectSuperHiddenRoom(roomsWithOpening[0]);
    }

    this.generateDoorsAndMap();

    roomsWithOpening = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(r => r.doors.length === 1);

    if (roomsWithOpening.length === 0) {
      roomsWithOpening = this.dungeon.rooms
        .flat()
        .filter(Boolean)
        .filter(r => r.doors.length === 2);
    }

    shuffleArray(roomsWithOpening);

    injectCakeRoom(roomsWithOpening[0]);

    this.generateDoorsAndMap();

    this.dungeon.warpTiles = [];

    const artifactRoom = this.dungeon.rooms
      .flat()
      .find(r => r.roomTypeName === 'ARTIFACT_ROOM');
    const firstWarpTileRooms = [
      artifactRoom?.aboveRoom,
      artifactRoom?.belowRoom,
      artifactRoom?.leftRoom,
      artifactRoom?.rightRoom,
    ]
      .filter(Boolean)
      .filter(
        r =>
          r!.roomTypeName !== 'PUZZLE_ROOM' &&
          r!.roomTypeName !== 'HIDDEN_ROOM' &&
          r!.roomTypeName !== 'SUPER_HIDDEN_ROOM' &&
          r!.roomTypeName !== 'CAKE_ROOM' &&
          r!.roomTypeName !== 'SMILE',
      ) as Room[];

    let roomsWithWarpTiles = firstWarpTileRooms;

    shuffleArray(roomsWithWarpTiles);

    const shuffledPuzzleRooms = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(r => r.roomTypeName === 'PUZZLE_ROOM');

    shuffleArray(shuffledPuzzleRooms);

    let secondWarpTileRooms = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(
        r =>
          r.roomTypeName !== 'PUZZLE_ROOM' &&
          r.roomTypeName !== 'HIDDEN_ROOM' &&
          r.roomTypeName !== 'SUPER_HIDDEN_ROOM' &&
          r.roomTypeName !== 'CAKE_ROOM' &&
          r.roomTypeName !== 'SMILE' &&
          !firstWarpTileRooms.includes(r) &&
          !firstWarpTileRooms.includes(r.aboveRoom) &&
          !firstWarpTileRooms.includes(r.belowRoom) &&
          !firstWarpTileRooms.includes(r.leftRoom) &&
          !firstWarpTileRooms.includes(r.rightRoom) &&
          Math.abs(firstWarpTileRooms[0].tilePosition[0] - r.tilePosition[0]) +
            Math.abs(
              firstWarpTileRooms[0].tilePosition[1] - r.tilePosition[1],
            ) >
            3,
      );
    if (secondWarpTileRooms.length === 0) {
      secondWarpTileRooms = this.dungeon.rooms
        .flat()
        .filter(Boolean)
        .filter(
          r =>
            r.roomTypeName !== 'PUZZLE_ROOM' &&
            r.roomTypeName !== 'HIDDEN_ROOM' &&
            r.roomTypeName !== 'SUPER_HIDDEN_ROOM' &&
            r.roomTypeName !== 'CAKE_ROOM' &&
            r.roomTypeName !== 'SMILE' &&
            !firstWarpTileRooms.includes(r) &&
            !firstWarpTileRooms.includes(r.aboveRoom) &&
            !firstWarpTileRooms.includes(r.belowRoom) &&
            !firstWarpTileRooms.includes(r.leftRoom) &&
            !firstWarpTileRooms.includes(r.rightRoom),
        );
    }
    shuffleArray(secondWarpTileRooms);

    const firstRoomWithWarpTile = roomsWithWarpTiles[0];
    const firstRoomRandomPosition = roomsWithWarpTiles[0].pickRandomCorner();
    const firstRoomWarpTile = new Warp();
    firstRoomWarpTile.addToScene(this.scene, [
      firstRoomWithWarpTile.tilePosition[0] * Room.ROOM_SIZE +
        firstRoomRandomPosition[0],
      firstRoomWithWarpTile.tilePosition[1] * Room.ROOM_SIZE +
        firstRoomRandomPosition[1],
    ]);
    firstRoomWarpTile.sprite.setDepth(1);
    firstRoomWarpTile.room = firstRoomWithWarpTile;
    this.dungeon.warpTiles.push(firstRoomWarpTile);

    roomsWithWarpTiles = secondWarpTileRooms;

    const secondRoomWithWarpTile = roomsWithWarpTiles[0];
    const secondRoomRandomPosition = roomsWithWarpTiles[0].pickRandomCorner();
    const secondRoomWarpTile = new Warp();
    secondRoomWarpTile.addToScene(this.scene, [
      secondRoomWithWarpTile.tilePosition[0] * Room.ROOM_SIZE +
        secondRoomRandomPosition[0],
      secondRoomWithWarpTile.tilePosition[1] * Room.ROOM_SIZE +
        secondRoomRandomPosition[1],
    ]);
    secondRoomWarpTile.sprite.setDepth(1);
    secondRoomWarpTile.room = secondRoomWithWarpTile;
    this.dungeon.warpTiles.push(secondRoomWarpTile);

    const wrongWarpRoom = this.addRoom(
      [Dungeon.MAP_SIZE - 2, Dungeon.MAP_SIZE - 2],
      RoomGenerator.ROOM_TYPES.SMILE,
    );

    wrongWarpRoom.roomBehavior = {
      onEnter: (dude: Dude, dungeon: Dungeon) => {
        if (
          dude.currentItem &&
          dungeon.artifacts.includes(dude.currentItem as Artifact)
        ) {
          const position = wrongWarpArtifactPositions.pop();
          dude.currentItem.tilePosition = [
            Math.floor(dude.tilePosition[0] / Room.ROOM_SIZE) * Room.ROOM_SIZE +
              position![0],
            Math.floor(dude.tilePosition[1] / Room.ROOM_SIZE) * Room.ROOM_SIZE +
              position![1],
          ];
          (dude.currentItem as Artifact).collected = true;
          (dude.currentItem as Artifact).sprite.play(
            (dude.currentItem as Artifact).anim.key,
          );
          dude.currentItem = null;
          if (wrongWarpArtifactPositions.length === 0) {
            useStore.getState().setBeatSmileRoom(true);
            this.dungeon.tokenLock.unlock(this.dungeon, true);
            this.scene.movementManager.setMapTile(
              this.dungeon.tokenLock.tilePosition[0],
              this.dungeon.tokenLock.tilePosition[1],
              2,
            );
            this.scene.movementManager.setMapTile(
              this.dungeon.tokenLock.adjacentTilePosition[0],
              this.dungeon.tokenLock.adjacentTilePosition[1],
              2,
            );
          }
        }
      },
    };

    this.generateDoorsAndMap();

    const wrongWarpRandomPosition = wrongWarpRoom.pickRandomCorner();
    const wrongWarpWarpTile = new Warp();
    wrongWarpWarpTile.addToScene(this.scene, [
      wrongWarpRoom.tilePosition[0] * Room.ROOM_SIZE +
        wrongWarpRandomPosition[0],
      wrongWarpRoom.tilePosition[1] * Room.ROOM_SIZE +
        wrongWarpRandomPosition[1],
    ]);
    wrongWarpWarpTile.sprite.setDepth(1);
    wrongWarpWarpTile.room = wrongWarpRoom;

    const planterRooms = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(
        r =>
          r.roomTypeName !== 'PUZZLE_ROOM' &&
          r.roomTypeName !== 'HIDDEN_ROOM' &&
          r.roomTypeName !== 'SUPER_HIDDEN_ROOM' &&
          r.roomTypeName !== 'CAKE_ROOM' &&
          r.roomTypeName !== 'SMILE',
      );

    shuffleArray(planterRooms);
    const planterRoom = planterRooms[0];
    const planterPosition = planterRoom.pickRandomPosition();
    const planter = new Planter();
    this.dungeon.planter = planter;
    planter.addToScene(this.scene, [
      planterRoom.tilePosition[0] * Room.ROOM_SIZE + planterPosition[0],
      planterRoom.tilePosition[1] * Room.ROOM_SIZE + planterPosition[1],
    ]);
    planter.room = planterRoom;
    planter.sprite.setDepth(1);

    const seedRooms = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(
        r =>
          r.roomTypeName !== 'PUZZLE_ROOM' &&
          r.roomTypeName !== 'HIDDEN_ROOM' &&
          r.roomTypeName !== 'SUPER_HIDDEN_ROOM' &&
          r.roomTypeName !== 'CAKE_ROOM' &&
          r.roomTypeName !== 'SMILE' &&
          r !== planterRoom,
      );

    shuffleArray(seedRooms);

    const seedRoom = seedRooms[0];
    const seedPosition = seedRoom.pickRandomPosition();
    const seed = new Seed();
    this.dungeon.seed = seed;
    seed.addToScene(this.scene, [
      seedRoom.tilePosition[0] * Room.ROOM_SIZE + seedPosition[0],
      seedRoom.tilePosition[1] * Room.ROOM_SIZE + seedPosition[1],
    ]);
    seed.sprite.setDepth(1);

    this.scene.dude.sprite.on('move', () => {
      const curRoom =
        this.dungeon.rooms[
          Math.floor(this.scene.dude.tilePosition[0] / Room.ROOM_SIZE)
        ]?.[Math.floor(this.scene.dude.tilePosition[1] / Room.ROOM_SIZE)];

      this.dungeon.warpTiles.forEach(warpTile => {
        const alpha =
          warpTile.room === curRoom ? 1 : warpTile.room.tiles.flat()[0].alpha;
        warpTile.sprite.setAlpha(alpha);
        warpTile.emitter.setAlpha({ start: alpha, end: 0 });
      });

      if (!this.dungeon.planter.done) {
        if (this.dungeon.planter.room === curRoom) {
          this.dungeon.planter.sprite.setAlpha(1);
          this.dungeon.planter.emitter?.setAlpha({ start: 1, end: 0 });
        } else {
          this.dungeon.planter.sprite.setAlpha(
            this.dungeon.planter.room.tiles.flat()[0].alpha,
          );
          this.dungeon.planter.emitter?.setAlpha({
            start: this.dungeon.planter.room.tiles.flat()[0].alpha,
            end: 0,
          });
        }
      }
    });

    let warpDenominator = 5;
    [...this.dungeon.warpTiles, wrongWarpWarpTile].forEach(
      (warpTile, index) => {
        warpTile.onWarp = () => {
          let targetWarpTile;
          const number = wrongWarpRandom.between(0, warpDenominator);
          if (number === 0 && warpTile !== wrongWarpWarpTile) {
            useStore.getState().setFoundWrongWarp(true);
            targetWarpTile = wrongWarpWarpTile;
            warpDenominator = 10;
          } else {
            warpDenominator -= 1;
            warpDenominator = Math.max(warpDenominator, 0);
            const warpTargetIndex = (index + 1) % this.dungeon.warpTiles.length;
            targetWarpTile = this.dungeon.warpTiles[warpTargetIndex];
          }
          this.scene.dude.tilePosition = [
            targetWarpTile.tilePosition[0],
            targetWarpTile.tilePosition[1],
          ];
          targetWarpTile.sprite.setAlpha(1);
          targetWarpTile.emitter.setAlpha({
            start: 1,
            end: 0,
          });
          targetWarpTile.room.tiles.flat().forEach(tile => {
            tile.alpha = 1;
          });
          this.scene.movementManager.updateRoomAlphas(this.scene);
        };
      },
    );

    this.generateDoorsAndMap();

    const tokenRoom = injectTokenRoom(wrongWarpRoom);

    tokenRoom!.roomBehavior = {
      onEnter: () => {
        const exit = new Exit();
        this.dungeon.tokenRoomExit = exit;
        exit.addToScene(this.scene, [
          Math.floor(this.scene.dude.tilePosition[0] / Room.ROOM_SIZE) *
            Room.ROOM_SIZE +
            3,
          Math.floor(this.scene.dude.tilePosition[1] / Room.ROOM_SIZE) *
            Room.ROOM_SIZE +
            3,
        ]);
        exit.sprite.tint = 0x000000;
      },
    };

    this.generateDoorsAndMap();

    const tokenLock = new Lock();

    tokenLock.addToScene(
      this.scene,
      tokenRoom!,
      this.dungeon.rooms,
      [],
      this.scene.textures.get('spritesheet').getFrameNames()[0],
    );
    tokenLock.setTint(0xffffff);
    this.dungeon.tokenLock = tokenLock;
  }

  private addRoom(
    tilePosition: number[],
    roomType: number[][] | null = null,
  ): Room {
    if (this.dungeon.rooms[tilePosition[0]]?.[tilePosition[1]]) {
      this.dungeon.rooms[tilePosition[0]]?.[tilePosition[1]].tiles
        .flat()
        .forEach(tile => tile.destroy());
    }

    const room = new Room(
      tilePosition[1] * Dungeon.TILE_SIZE * Room.ROOM_SIZE,
      tilePosition[0] * Dungeon.TILE_SIZE * Room.ROOM_SIZE,
      roomType,
    );
    room.tilePosition = tilePosition;

    this.dungeon.rooms[tilePosition[0]] ||= [];
    this.dungeon.rooms[tilePosition[0]][tilePosition[1]] = room;
    return room;
  }

  private generateDrunkenWalkDungeon = (
    location: number[],
    limit = 10,
  ): Room[][] => {
    const allRooms = this.dungeon.rooms.flat().filter(Boolean);
    if (allRooms.length > limit) {
      return this.dungeon.rooms;
    }

    const directions: DoorLocations[] = ['top', 'bottom', 'left', 'right'];
    shuffleArray(directions);
    const newLocations = directions
      .map(direction => {
        const newLocation = [location[0], location[1]];
        switch (direction) {
          case 'top':
            newLocation[0] -= 1;
            break;
          case 'bottom':
            newLocation[0] += 1;
            break;
          case 'left':
            newLocation[1] -= 1;
            break;
          case 'right':
            newLocation[1] += 1;
            break;
          default:
            break;
        }
        if (newLocation[0] < 0 || newLocation[0] > Dungeon.MAP_SIZE - 1) {
          return null;
        }
        if (newLocation[1] < 0 || newLocation[1] > Dungeon.MAP_SIZE - 1) {
          return null;
        }
        if (this.dungeon.rooms[newLocation[0]]?.[newLocation[1]]) {
          return null;
        }
        return [direction, newLocation];
      })
      .filter(Boolean)
      .slice(0, 1);
    while (newLocations.length > 0) {
      const [, newLocation] = newLocations.pop() as [DoorLocations, number[]];

      if (!newLocation) {
        return this.dungeon.rooms;
      }

      const room = this.addRoom(newLocation);
      const i = newLocation[0];
      const j = newLocation[1];
      let doorLocations: DoorLocations[] = [];
      doorLocations = doorLocations.concat(
        this.dungeon.rooms[i - 1]?.[j] ? ['top'] : [],
      );
      doorLocations = doorLocations.concat(
        this.dungeon.rooms[i + 1]?.[j] ? ['bottom'] : [],
      );
      doorLocations = doorLocations.concat(
        this.dungeon.rooms[i]?.[j - 1] ? ['left'] : [],
      );
      doorLocations = doorLocations.concat(
        this.dungeon.rooms[i]?.[j + 1] ? ['right'] : [],
      );
      room.doors = doorLocations;
      this.generateDoorsAndMap();
      RoomGenerator.chooseRoomType(room);

      this.generateDrunkenWalkDungeon(newLocation as number[], limit);
    }
    return this.dungeon.rooms;
  };

  private generateDoorsAndMap = () => {
    for (let i = 0; i < Dungeon.MAP_SIZE; i += 1) {
      for (let j = 0; j < Dungeon.MAP_SIZE; j += 1) {
        if (!this.dungeon.rooms[i]?.[j]) {
          continue;
        }
        const room = this.dungeon.rooms[i][j];
        const aboveRoom = this.dungeon.rooms[i - 1]?.[j];
        const belowRoom = this.dungeon.rooms[i + 1]?.[j];
        const leftRoom = this.dungeon.rooms[i]?.[j - 1];
        const rightRoom = this.dungeon.rooms[i]?.[j + 1];
        room.aboveRoom = aboveRoom;
        room.belowRoom = belowRoom;
        room.leftRoom = leftRoom;
        room.rightRoom = rightRoom;

        let doorLocations: DoorLocations[] = [];
        doorLocations = doorLocations.concat(aboveRoom ? ['top'] : []);
        doorLocations = doorLocations.concat(belowRoom ? ['bottom'] : []);
        doorLocations = doorLocations.concat(leftRoom ? ['left'] : []);
        doorLocations = doorLocations.concat(rightRoom ? ['right'] : []);

        room.doors = doorLocations;

        room.tilePosition = [i, j];

        if (room.roomType) {
          if (!aboveRoom) {
            room.roomType[0][3] = 0;
            RoomGenerator.generate(room, this.scene);
          }
          if (!belowRoom) {
            room.roomType[6][3] = 0;
            RoomGenerator.generate(room, this.scene);
          }
          if (!leftRoom) {
            room.roomType[3][0] = 0;
            RoomGenerator.generate(room, this.scene);
          }
          if (!rightRoom) {
            room.roomType[3][6] = 0;
            RoomGenerator.generate(room, this.scene);
          }

          if (
            !['HIDDEN_ROOM', 'SUPER_HIDDEN_ROOM', 'CAKE_ROOM'].includes(
              room.roomTypeName || '',
            )
          ) {
            room.roomType![0][3] =
              room.roomType![0][3] !== 3 && aboveRoom
                ? 2
                : room.roomType![0][3];
            room.roomType![6][3] =
              room.roomType![6][3] !== 3 && belowRoom
                ? 2
                : room.roomType![6][3];
            room.roomType![3][0] =
              room.roomType![3][0] !== 3 && leftRoom ? 2 : room.roomType![3][0];
            room.roomType![3][6] =
              room.roomType![3][6] !== 3 && rightRoom
                ? 2
                : room.roomType![3][6];
          } else {
            room.roomType![0][3] =
              room.roomType![0][3] !== 3 && aboveRoom
                ? 8
                : room.roomType![0][3];
            room.roomType![6][3] =
              room.roomType![6][3] !== 3 && belowRoom
                ? 8
                : room.roomType![6][3];
            room.roomType![3][0] =
              room.roomType![3][0] !== 3 && leftRoom ? 8 : room.roomType![3][0];
            room.roomType![3][6] =
              room.roomType![3][6] !== 3 && rightRoom
                ? 8
                : room.roomType![3][6];
          }

          if (
            ['HIDDEN_ROOM', 'SUPER_HIDDEN_ROOM', 'CAKE_ROOM'].includes(
              aboveRoom?.roomTypeName || '',
            )
          ) {
            room.roomType![0][3] = 8;
            aboveRoom.roomType![6][3] = 8;
            RoomGenerator.generate(aboveRoom, this.scene);
          }
          if (
            ['HIDDEN_ROOM', 'SUPER_HIDDEN_ROOM', 'CAKE_ROOM'].includes(
              belowRoom?.roomTypeName || '',
            )
          ) {
            room.roomType![6][3] = 8;
            belowRoom.roomType![0][3] = 8;
            RoomGenerator.generate(belowRoom, this.scene);
          }
          if (
            ['HIDDEN_ROOM', 'SUPER_HIDDEN_ROOM', 'CAKE_ROOM'].includes(
              leftRoom?.roomTypeName || '',
            )
          ) {
            room.roomType![3][0] = 8;
            leftRoom.roomType![3][6] = 8;
            RoomGenerator.generate(leftRoom, this.scene);
          }
          if (
            ['HIDDEN_ROOM', 'SUPER_HIDDEN_ROOM', 'CAKE_ROOM'].includes(
              rightRoom?.roomTypeName || '',
            )
          ) {
            room.roomType![3][6] = 8;
            rightRoom.roomType![3][0] = 8;
            RoomGenerator.generate(rightRoom, this.scene);
          }
          RoomGenerator.generate(room, this.scene);
        }
      }
    }
  };

  private generateKeys = () => {
    const roomsToGridPosition = this.dungeon.rooms
      .flat()
      .filter(Boolean)
      .filter(
        room =>
          room.roomTypeName !== 'PUZZLE_ROOM' &&
          room.roomTypeName !== 'DEFAULT' &&
          this.dungeon.locks.every(
            lock => lock.room !== room && lock.adjacentRoom !== room,
          ),
      )
      .map(room => room.tilePosition);

    for (let i = 0; i < 3; i += 1) {
      shuffleArray(roomsToGridPosition);
      const keyRoomPosition = roomsToGridPosition[0];
      const key = new Key();
      this.dungeon.keys[i] = key;

      key.pathToKey = this.findShortestPathToRoom(
        [Dungeon.MIDPOINT, Dungeon.MIDPOINT],
        keyRoomPosition,
      );
      const room = this.dungeon.rooms[keyRoomPosition[0]]?.[keyRoomPosition[1]];
      const positionInRoom = room.pickRandomPosition();
      key.addToScene(this.scene, [
        keyRoomPosition[0] * Room.ROOM_SIZE + positionInRoom[0],
        keyRoomPosition[1] * Room.ROOM_SIZE + positionInRoom[1],
      ]);
      key.sprite.setDepth(1);
      key.sprite.tint = this.colors[i];
      room.collectibles.push(key.sprite);
    }
  };

  private findShortestPathToRoom = (
    startPos: number[],
    endPos: number[],
  ): Room[] => {
    const queue: Room[] = [];
    const visited: Room[] = [];
    const path: Room[] = [];
    const startRoom = this.dungeon.rooms[startPos[0]]?.[startPos[1]];
    const endRoom = this.dungeon.rooms[endPos[0]]?.[endPos[1]];
    queue.push(startRoom);
    while (queue.length > 0) {
      let currentRoom = queue.shift() as Room;
      visited.push(currentRoom);
      if (currentRoom === endRoom) {
        while (currentRoom) {
          path.push(currentRoom);
          currentRoom = currentRoom.parent;
        }
        return path.reverse();
      }
      currentRoom.doors.forEach(direction => {
        const newLocation = [
          currentRoom.tilePosition[0],
          currentRoom.tilePosition[1],
        ];
        switch (direction) {
          case 'top':
            newLocation[0] -= 1;
            break;
          case 'bottom':
            newLocation[0] += 1;
            break;
          case 'left':
            newLocation[1] -= 1;
            break;
          case 'right':
            newLocation[1] += 1;
            break;
          default:
            break;
        }
        const newRoom = this.dungeon.rooms[newLocation[0]]?.[newLocation[1]];
        if (newRoom && !visited.includes(newRoom)) {
          newRoom.parent = currentRoom;
          queue.push(newRoom);
        }
      });
    }
    return [];
  };
}
