import PuzzleButton from '../PuzzleButton';
import useStore from '../react/useStore';
import Room from '../Room';
import { shuffleArray } from '../util';
import Puzzle from './Puzzle';

export default class Lights extends Puzzle {
  generate(): void {
    const buttonPosition: number[][][] = [
      [
        [1, 1],
        [1, 3],
        [1, 5],
      ],
      [
        [3, 1],
        [3, 3],
        [3, 5],
      ],
      [
        [5, 1],
        [5, 3],
        [5, 5],
      ],
    ];
    const puzzleButtons: PuzzleButton[][] = [];
    for (let i = 0; i < 3; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        const puzzleButton = new PuzzleButton();
        puzzleButton.addToScene(this.scene, [
          this.room.tilePosition[0] * Room.ROOM_SIZE + buttonPosition[i][j][0],
          this.room.tilePosition[1] * Room.ROOM_SIZE + buttonPosition[i][j][1],
        ]);
        puzzleButton.sprite.setDepth(1);
        puzzleButton.press();
        puzzleButtons[i] = puzzleButtons[i] || [];
        puzzleButtons[i][j] = puzzleButton;
      }
    }

    const flattenedButtons = [...puzzleButtons.flat()];
    shuffleArray(flattenedButtons);

    for (let i = 0; i < 5; i += 1) {
      this.pressButton(flattenedButtons[i], puzzleButtons);
    }

    const { dude } = this.scene;

    let presses = 0;

    dude.sprite.on('move', () => {
      if (this.completed) {
        this.revealTheWorld();
        return;
      }
      puzzleButtons
        .flat()
        .forEach(puzzleButton =>
          puzzleButton.sprite.setAlpha(
            this.room === this.getCurrentRoom()
              ? 1
              : this.room.tiles.flat()[0].alpha,
          ),
        );
      const pressedButton = puzzleButtons
        .flat()
        .find(
          thing =>
            thing.tilePosition[0] === dude.tilePosition[0] &&
            thing.tilePosition[1] === dude.tilePosition[1],
        );
      if (pressedButton) {
        presses += 1;
        if (presses >= 10) {
          useStore.getState().setNoMistakes(false);
        }
        pressedButton.toggle();
        for (let i = 0; i < 3; i += 1) {
          for (let j = 0; j < 3; j += 1) {
            if (puzzleButtons[i][j] === pressedButton) {
              puzzleButtons[i - 1]?.[j]?.toggle();
              // puzzleButtons[i - 2]?.[j]?.toggle();
              puzzleButtons[i + 1]?.[j]?.toggle();
              // puzzleButtons[i + 2]?.[j]?.toggle();
              puzzleButtons[i]?.[j - 1]?.toggle();
              // puzzleButtons[i]?.[j - 2]?.toggle();
              puzzleButtons[i]?.[j + 1]?.toggle();
              // puzzleButtons[i]?.[j + 2]?.toggle();
            }
          }
        }
        if (puzzleButtons.flat().every(button => button.pressed)) {
          this.completed = true;
          this.solveSuperHiddenPuzzle();
          useStore.getState().setSuperHiddenPuzzleSolved(true);
          puzzleButtons
            .flat()
            .forEach(button => button.sprite.setTint(0x00ff00));
          this.scene.time.addEvent({
            delay: 1000,
            callback: () => {
              puzzleButtons.flat().forEach(button => button.sprite.destroy());
            },
          });
        }
      }
    });
  }

  private pressButton(
    button: PuzzleButton,
    puzzleButtons: PuzzleButton[][],
  ): void {
    button.toggle();
    for (let i = 0; i < 3; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        if (puzzleButtons[i][j] === button) {
          puzzleButtons[i - 1]?.[j]?.toggle();
          puzzleButtons[i - 2]?.[j]?.toggle();
          puzzleButtons[i + 1]?.[j]?.toggle();
          puzzleButtons[i + 1]?.[j]?.toggle();
          puzzleButtons[i]?.[j - 1]?.toggle();
          puzzleButtons[i]?.[j - 2]?.toggle();
          puzzleButtons[i]?.[j + 1]?.toggle();
          puzzleButtons[i]?.[j + 2]?.toggle();
        }
      }
    }
  }
}
