import Phaser from 'phaser';
import PuzzleButton from '../PuzzleButton';
import useStore from '../react/useStore';
import Room from '../Room';
import { shuffleArray } from '../util';
import Puzzle from './Puzzle';

export default class SimonSays extends Puzzle {
  generate(): void {
    const randomPositions: number[][] = [];
    const puzzleButtons: PuzzleButton[] = [];
    for (let i = 0; i < 5; i += 1) {
      let randomPosition = this.room.pickRandomPosition(true);
      while (
        randomPositions.some(
          position =>
            position[0] === randomPosition[0] &&
            position[1] === randomPosition[1],
        )
      ) {
        randomPosition = this.room.pickRandomPosition(true);
      }
      randomPositions.push(randomPosition);
      const puzzleButton = new PuzzleButton();

      puzzleButtons.push(puzzleButton);
    }
    shuffleArray(puzzleButtons);
    puzzleButtons.forEach((puzzleButton, index) => {
      puzzleButton.addToScene(
        this.scene,
        [
          this.room.tilePosition[0] * Room.ROOM_SIZE +
            randomPositions[index][0],
          this.room.tilePosition[1] * Room.ROOM_SIZE +
            randomPositions[index][1],
        ],
        (index + 1).toString(),
      );
      puzzleButton.sprite.setDepth(1);
      puzzleButton.text.setDepth(1);
    });
    const timers: Phaser.Time.TimerEvent[] = [];
    for (let i = 0; i < puzzleButtons.length; i += 1) {
      timers.push(
        this.scene.time.addEvent({
          startAt: i * 200,
          delay: 2000,
          callback: () => {
            puzzleButtons.forEach(puzzleButton => puzzleButton.release());
            puzzleButtons[puzzleButtons.length - 1 - i].press();
          },
          loop: true,
        }),
      );
    }

    let currentButton = 0;
    const { dude } = this.scene;

    dude.sprite.on('move', () => {
      if (this.completed) {
        return;
      }
      puzzleButtons.forEach(puzzleButton => {
        puzzleButton.sprite.setAlpha(
          this.room === this.getCurrentRoom()
            ? 1
            : this.room.tiles.flat()[0].alpha,
        );
        puzzleButton.text.setAlpha(
          this.room === this.getCurrentRoom()
            ? 1
            : this.room.tiles.flat()[0].alpha,
        );
        puzzleButton.text.setAlpha(
          timers.some(timer => timer.paused) ? 0 : puzzleButton.text.alpha,
        );
      });
      if (timers.some(timer => timer.paused)) {
        const currentlyStoodButton = puzzleButtons.find(
          puzzleButton =>
            puzzleButton.tilePosition[0] === dude.tilePosition[0] &&
            puzzleButton.tilePosition[1] === dude.tilePosition[1],
        );
        if (currentlyStoodButton) {
          if (
            puzzleButtons[currentButton].tilePosition[0] ===
              dude.tilePosition[0] &&
            puzzleButtons[currentButton].tilePosition[1] ===
              dude.tilePosition[1]
          ) {
            puzzleButtons[currentButton].press();
            currentButton += 1;
            if (currentButton === puzzleButtons.length) {
              puzzleButtons.forEach(puzzleButton =>
                puzzleButton.sprite.destroy(),
              );
              this.addArtifact();
            }
          } else if (!currentlyStoodButton.pressed) {
            currentButton = 0;
            puzzleButtons.forEach(puzzleButton => {
              puzzleButton.text.alpha = 0;
            });
            useStore.getState().setNoMistakes(false);
            timers.forEach(timer => {
              timer.paused = false;
            });
          }
        }
      } else if (
        currentButton === 0 &&
        puzzleButtons[0].tilePosition[0] === dude.tilePosition[0] &&
        puzzleButtons[0].tilePosition[1] === dude.tilePosition[1]
      ) {
        puzzleButtons.forEach(puzzleButton => puzzleButton.release());
        puzzleButtons.forEach(puzzleButton => {
          puzzleButton.text.alpha = 0;
        });
        currentButton += 1;
        puzzleButtons[0].press();
        timers.forEach(timer => {
          timer.paused = true;
        });
      }
    });
  }
}
