import { format } from 'date-fns';
import Phaser from 'phaser';
import Dungeon from './Dungeon';
import useStore from './react/useStore';
import Room from './Room';
import Main from './scenes/Main';
import { DEFAULT_ALPHA, start } from './util';

export default class Warp {
  sprite: Phaser.GameObjects.Sprite;

  particles: Phaser.GameObjects.Particles.ParticleEmitterManager;

  tilePosition: number[];

  onWarp: () => void;

  room: Room;

  emitter: Phaser.GameObjects.Particles.ParticleEmitter;

  active: boolean;

  addToScene(scene: Main, tilePosition: number[]) {
    this.tilePosition = tilePosition;
    this.sprite = scene.add.sprite(
      Dungeon.TILE_SIZE * tilePosition[1],
      Dungeon.TILE_SIZE * tilePosition[0],
      'warp',
      0,
    );
    scene.anims.create({
      key: 'warp-idle',
      frames: scene.anims.generateFrameNumbers('warp', { start: 0, end: 3 }),
      frameRate: 10,
      repeat: -1,
    });

    this.sprite.scale = 0;
    this.sprite.alpha = DEFAULT_ALPHA;
    scene.add.tween({
      targets: this.sprite,
      scale: 1,
      duration: 500,
    });
    this.particles = scene.add.particles('particle');
    this.emitter = this.particles.createEmitter({
      x: this.sprite.x,
      y: this.sprite.y,
      // angle: this.sprite.rotation,
      // speed: { min: -100, max: 100 },
      lifespan: 300,
      quantity: 2,
      blendMode: 'ADD',
      speedY: -20,
      emitZone: {
        type: 'random',
        /* @ts-ignore */
        source: new Phaser.Geom.Circle(0, 0, 8),
      },
      alpha: { start: 1, end: 0 },
      active: this.active,
    });
    this.emitter.setAlpha(DEFAULT_ALPHA);
    this.emitter.start();
    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.sprite.setPipeline('Light2D');
      this.particles.setPipeline('Light2D');
    }
    scene.dude.sprite.on('move', (previousPosition, currentPosition) => {
      if (this.active) {
        this.sprite.tint = 0xffffff;
        this.emitter.setTint(0xffffff);
        this.emitter.active = true;
      } else {
        this.sprite.tint = 0xdddddd;
        this.emitter.setTint(0xdddddd);
        this.emitter.active = false;
      }
      if (
        this.active &&
        currentPosition[0] === this.tilePosition[0] &&
        currentPosition[1] === this.tilePosition[1]
      ) {
        if (scene.dude.currentItem) {
          useStore.getState().setUsedPortalWhileHoldingItem(true);
        }
        scene.dude.done = true;
        scene.add.tween({
          targets: scene.dude.sprite,
          alpha: 0,
          duration: 200,
          delay: 300,
        });
        scene.add.tween({
          targets: scene.dude.sprite,
          scaleX: 0,
          duration: 200,
          delay: 300,
        });
        scene.add.tween({
          targets: scene.dude.sprite,
          scaleY: 2,
          duration: 200,
          delay: 300,
        });
        scene.time.addEvent({
          delay: 500,
          callback: () => this.onWarp?.(),
        });
        scene.add.tween({
          targets: scene.dude.sprite,
          alpha: 1,
          duration: 200,
          delay: 800,
        });
        scene.add.tween({
          targets: scene.dude.sprite,
          scaleX: 1,
          duration: 200,
          delay: 800,
        });
        scene.add.tween({
          targets: scene.dude.sprite,
          scaleY: 1,
          duration: 200,
          delay: 800,
          onComplete: () => {
            scene.dude.done = false;
          },
        });
      }
    });
  }

  update(): void {}
}
