import { format } from 'date-fns';
import Phaser from 'phaser';
import Dude from './Dude';
import Dungeon from './Dungeon';
import useStore from './react/useStore';
import Room from './Room';
import Main from './scenes/Main';
import { DEFAULT_ALPHA, start } from './util';

export default class Planter {
  sprite: Phaser.GameObjects.Sprite;

  particles: Phaser.GameObjects.Particles.ParticleEmitterManager;

  tilePosition: number[];

  done: boolean;

  room: Room;

  collectible: boolean;

  emitter: Phaser.GameObjects.Particles.ParticleEmitter;

  frameNames: string[];

  addToScene(scene: Main, tilePosition: number[]) {
    this.frameNames = scene.textures.get('spritesheet').getFrameNames();
    this.tilePosition = tilePosition;
    this.sprite = scene.add.sprite(
      Dungeon.TILE_SIZE * tilePosition[1],
      Dungeon.TILE_SIZE * tilePosition[0],
      'spritesheet',
      this.frameNames[14],
    );

    scene.anims.create({
      key: 'planter-idle',
      frames: scene.anims.generateFrameNumbers('planter', { start: 0, end: 7 }),
      frameRate: 5,
      repeat: -1,
    }) as Phaser.Animations.Animation;

    scene.anims.create({
      key: 'flower-idle',
      frames: scene.anims.generateFrameNumbers('flower', { start: 0, end: 7 }),
      frameRate: 5,
      repeat: -1,
    }) as Phaser.Animations.Animation;

    this.sprite.alpha = DEFAULT_ALPHA;
    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.sprite.setPipeline('Light2D');
    }
  }

  plant(dude: Dude, scene: Main): void {
    let moves = 0;
    dude.sprite.on('move', () => {
      if (this.done) return;
      moves += 1;
      if (moves >= 100) {
        if (moves === 100) {
          this.sprite.play('flower-idle');
          this.collectible = true;
          this.particles = scene.add.particles('particle');
          this.emitter = this.particles.createEmitter({
            x: this.sprite.x,
            y: this.sprite.y,
            // angle: this.sprite.rotation,
            // speed: { min: -100, max: 100 },
            lifespan: 300,
            quantity: 1,
            blendMode: 'ADD',
            speedY: -10,
            emitZone: {
              type: 'random',
              /* @ts-ignore */
              source: new Phaser.Geom.Circle(0, 0, 4),
            },
            tint: 0xffff00,
            alpha: { start: 1, end: 0 },
          });
          this.emitter.start();
        }
        this.sprite.setTexture('spritesheet', this.frameNames[16]);
        if (
          dude.tilePosition[0] === this.tilePosition[0] &&
          dude.tilePosition[1] === this.tilePosition[1]
        ) {
          useStore.getState().setGreenThumb(true);
          this.done = true;
          scene.tweens.add({
            targets: this.sprite,
            y: this.sprite.y - 20,
            duration: 300,
          });
          scene.tweens.add({
            targets: this.sprite,
            alpha: 0,
            duration: 300,
            onComplete: () => {
              this.sprite.destroy();
            },
          });
          this.emitter.stop();
        }
      } else if (moves > 5) {
        if (
          dude.tilePosition[0] === this.tilePosition[0] &&
          dude.tilePosition[1] === this.tilePosition[1]
        ) {
          this.sprite.setTexture('spritesheet', this.frameNames[14]);
          useStore.getState().setPlantKiller(true);
          this.done = true;
          scene.tweens.add({
            targets: this.sprite,
            alpha: 0,
            duration: 1000,
            onComplete: () => {
              this.sprite.destroy();
            },
          });
        } else if (moves === 6) {
          this.sprite.play('planter-idle');
        }
      }
    });
  }

  elapsed = 0;

  update(): void {
    if (this.collectible && !this.done) {
      this.sprite.y -= Math.sin(this.elapsed) * 0.05;
      this.elapsed += 0.05;
    }
  }
}
