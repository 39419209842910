import QrCode from '../QrCode';
import useStore from '../react/useStore';
import Room from '../Room';
import Puzzle from './Puzzle';

export default class QrCodePuzzle extends Puzzle {
  generate(): void {
    const qrCode = new QrCode();
    qrCode.addToScene('foobar', this.scene, [
      this.room.tilePosition[0] * Room.ROOM_SIZE + 3,
      this.room.tilePosition[1] * Room.ROOM_SIZE + 3,
    ]);
    qrCode.sprite.setDepth(1);

    const { dude } = this.scene;

    let directions: string[] = [];

    let puzzleStarted = false;
    dude.sprite.on('move', () => {
      if (this.getCurrentRoom() === this.room) {
        puzzleStarted = true;
        qrCode.sprite.setAlpha(1);
      }

      qrCode.sprite.setAlpha(
        this.room === this.getCurrentRoom()
          ? 1
          : this.room.tiles.flat()[0].alpha,
      );
    });

    dude.sprite.on('moveAttempt', direction => {
      if (!puzzleStarted) {
        return;
      }
      if (this.completed) {
        this.revealTheWorld();
        return;
      }

      directions.push(direction);

      directions = directions.slice(-8);

      if (
        directions.length === useStore.getState().directionsForPath.length &&
        directions.every(
          (_, index) =>
            directions[index] === useStore.getState().directionsForPath[index],
        )
      ) {
        this.completed = true;
        this.solveSuperHiddenPuzzle();
        qrCode.sprite.setTint(0x00ff00);

        this.scene.time.addEvent({
          delay: 1000,
          callback: () => {
            qrCode.sprite.destroy();
            useStore.getState().setSuperHiddenPuzzleSolved(true);
          },
        });
      }
    });
  }
}
