/* eslint-disable no-loop-func */
import { format } from 'date-fns';
import Phaser from 'phaser';
import Dungeon from './Dungeon';
import Room from './Room';
import { DEFAULT_ALPHA, start } from './util';

export default class Lock {
  sprite: Phaser.GameObjects.Image;

  adjacentSprite: Phaser.GameObjects.Image;

  tilePosition: number[];

  adjacentTilePosition: number[];

  room: Room;

  adjacentRoom: Room;

  text: Phaser.GameObjects.Text;

  destructionImminent: boolean;

  addToScene(
    scene: Phaser.Scene,
    room: Room,
    rooms: Room[][],
    locks: Lock[],
    sprite = scene.textures.get('spritesheet').getFrameNames()[1],
  ) {
    let direction;
    let doorPosition;
    let position;
    let adjacentPosition;
    let adjacentRoom;

    while (true) {
      [direction, doorPosition] = room.pickRandomDoor();
      position = [
        room.tilePosition[0] * Room.ROOM_SIZE + doorPosition[0],
        room.tilePosition[1] * Room.ROOM_SIZE + doorPosition[1],
      ];
      switch (direction) {
        case 'top':
          adjacentPosition = [position[0] - 1, position[1]];
          adjacentRoom = rooms[room.tilePosition[0] - 1][room.tilePosition[1]];
          break;
        case 'right':
          adjacentPosition = [position[0], position[1] + 1];
          adjacentRoom = rooms[room.tilePosition[0]][room.tilePosition[1] + 1];
          break;
        case 'bottom':
          adjacentPosition = [position[0] + 1, position[1]];
          adjacentRoom = rooms[room.tilePosition[0] + 1][room.tilePosition[1]];
          break;
        case 'left':
          adjacentPosition = [position[0], position[1] - 1];
          adjacentRoom = rooms[room.tilePosition[0]][room.tilePosition[1] - 1];
          break;
        default:
          break;
      }
      if (
        !locks.some(
          lock =>
            (position[0] === lock.tilePosition[0] &&
              position[1] === lock.tilePosition[1]) ||
            (adjacentPosition[0] === lock.adjacentTilePosition[0] &&
              adjacentPosition[1] === lock.adjacentTilePosition[1]) ||
            (position[0] === lock.adjacentTilePosition[0] &&
              position[1] === lock.adjacentTilePosition[1]) ||
            (adjacentPosition[0] === lock.tilePosition[0] &&
              adjacentPosition[1] === lock.tilePosition[1]),
        )
      ) {
        break;
      }
    }

    this.sprite = scene.add.image(
      position[1] * Dungeon.TILE_SIZE,
      position[0] * Dungeon.TILE_SIZE,
      'spritesheet',
      sprite,
    );
    this.adjacentSprite = scene.add.image(
      adjacentPosition[1] * Dungeon.TILE_SIZE,
      adjacentPosition[0] * Dungeon.TILE_SIZE,
      'spritesheet',
      sprite,
    );

    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.sprite.setPipeline('Light2D');
      this.adjacentSprite.setPipeline('Light2D');
    }
    this.room = room;
    this.adjacentRoom = adjacentRoom;

    room.roomType![doorPosition[0]][doorPosition[1]] = 3;
    this.adjacentTilePosition = position;
    adjacentRoom.roomType![
      adjacentPosition[0] - adjacentRoom.tilePosition[0] * Room.ROOM_SIZE
    ][adjacentPosition[1] - adjacentRoom.tilePosition[1] * Room.ROOM_SIZE] = 3;
    this.tilePosition = adjacentPosition;
  }

  setTint(tint: number) {
    this.sprite.setTint(tint);
    this.adjacentSprite.setTint(tint);
    this.sprite.alpha = DEFAULT_ALPHA;
    this.adjacentSprite.alpha = DEFAULT_ALPHA;
  }

  unlock(dungeon: Dungeon, skipSplice = false) {
    this.sprite.destroy();
    this.adjacentSprite.destroy();
    if (!skipSplice) {
      dungeon.locks.splice(dungeon.locks.indexOf(this), 1);
    }
  }
}
