import { format } from 'date-fns';
import Phaser from 'phaser';
import Collectible from './Collectible';
import Dungeon from './Dungeon';
import Main from './scenes/Main';
import { DEFAULT_ALPHA, start } from './util';

export default class Artifact implements Collectible {
  sprite: Phaser.GameObjects.Sprite;

  tilePosition: number[];

  static ARTIFACT_SPRITES = [9, 11, 12];

  static ANIMATED_TEXTURES = {
    9: 'relic00',
    11: 'relic01',
    12: 'relic02',
  };

  static artifactSpriteIndex = 0;

  collected: boolean = false;

  anim: Phaser.Animations.Animation;

  addToScene(scene: Main, tilePosition: number[]) {
    const spriteNumber =
      Artifact.ARTIFACT_SPRITES[Artifact.artifactSpriteIndex];
    this.tilePosition = tilePosition;
    this.sprite = scene.add.sprite(
      this.tilePosition[1] * Dungeon.TILE_SIZE,
      this.tilePosition[0] * Dungeon.TILE_SIZE,
      'spritesheet',
      scene.textures.get('spritesheet').getFrameNames()[
        Artifact.ARTIFACT_SPRITES[Artifact.artifactSpriteIndex]
      ],
    );
    this.anim = scene.anims.create({
      key: `${Artifact.ANIMATED_TEXTURES[spriteNumber]}-idle`,
      frames: scene.anims.generateFrameNumbers(
        Artifact.ANIMATED_TEXTURES[spriteNumber],
        { start: 0, end: 3 },
      ),
      frameRate: 5,
      repeat: -1,
    }) as Phaser.Animations.Animation;

    Artifact.artifactSpriteIndex += 1;
    this.sprite.alpha = DEFAULT_ALPHA;
    this.sprite.scale = 0;
    this.sprite.setDepth(1);
    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.sprite.setPipeline('Light2D');
    }
    scene.add.tween({
      targets: this.sprite,
      scale: 1,
      duration: 500,
    });
  }

  update(time: number, delta: number): void {
    this.sprite.x +=
      (this.tilePosition[1] * Dungeon.TILE_SIZE - this.sprite.x) * delta * 0.02;
    this.sprite.y +=
      (this.tilePosition[0] * Dungeon.TILE_SIZE - this.sprite.y) * delta * 0.02;
  }
}
