import { format } from 'date-fns';
import Phaser from 'phaser';
import Dungeon from './Dungeon';
import Room from './Room';
import { DEFAULT_ALPHA, shuffleArray, start } from './util';

declare global {
  interface Window {
    tileTypes: number[];
  }
}

window.tileTypes = [];

export default class RoomGenerator {
  static ROOM_TYPES = {
    DEFAULT: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    TOKEN_ROOM: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    ARTIFACT_ROOM: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 7, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 7, 1, 7, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    PUZZLE_ROOM: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    HIDDEN_ROOM: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    SUPER_HIDDEN_ROOM: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    CAKE_ROOM: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    NO_CENTER: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    MINI_MINUS: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    // MINI_PLUS: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 1, 1, 0, 1, 1, 0],
    //   [0, 1, 0, 0, 0, 1, 0],
    //   [0, 1, 1, 0, 1, 1, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    RANDO: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    RANDO2: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    RANDO3: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    RANDO4: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 0, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    RANDO5: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    RANDO6: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    RANDO7: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    RANDO8: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 0, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    MINI_VERT_MINUS: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    MINI_DOWN_RIGHT: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    MINI_UP_LEFT: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    MINI_CROSS: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 0, 1, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 1, 0, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    // WINDING: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 1, 1, 0, 0],
    //   [0, 0, 0, 0, 1, 0, 0],
    //   [0, 1, 1, 0, 1, 0, 0],
    //   [0, 0, 1, 0, 1, 0, 0],
    //   [0, 0, 1, 1, 1, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    // WINDING_REVERSE: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 1, 1, 0, 0, 0],
    //   [0, 0, 1, 0, 0, 0, 0],
    //   [0, 0, 1, 0, 1, 1, 0],
    //   [0, 0, 1, 0, 1, 0, 0],
    //   [0, 0, 1, 1, 1, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    // NO_CORNERS: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 1, 1, 1, 0, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 0, 1, 1, 1, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    SINGLE_CORNER: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    SINGLE_CORNER_REVERSE: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 1, 1, 1, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    // NO_CORNERS2: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    // NO_CORNERS3: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 1, 1, 1, 0, 0],
    //   [0, 0, 1, 1, 1, 0, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 0, 1, 1, 1, 0, 0],
    //   [0, 0, 1, 1, 1, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    // NO_CORNERS4: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 1, 1, 1, 0, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 0, 1, 1, 1, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    // PLUS: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    // PLUS_WITH_MISSING_CENTER: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 1, 1, 0, 1, 1, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    // UP_LEFT_DOWN: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 1, 1, 0, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    // UP_RIGHT_DOWN: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 0, 1, 1, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    // LEFT_RIGHT_DOWN: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 1, 1, 0, 1, 1, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 1, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    MINUS: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    VERT_MINUS: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    CIRCLE: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    HALLWAYS: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0],
      [0, 0, 0, 1, 1, 0, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 0, 1, 1, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    HALLWAYS_REVERSE: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0],
      [0, 0, 1, 1, 0, 0, 0],
      [0, 1, 1, 0, 1, 1, 0],
      [0, 0, 0, 1, 1, 0, 0],
      [0, 0, 0, 1, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    HALLWAYS2: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    HALLWAYS2_REVERSE: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 0, 0, 0],
      [0, 1, 0, 0, 0, 0, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 0, 0, 0, 0, 1, 0],
      [0, 0, 0, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    // HALLWAYS4: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 0, 0, 1, 0, 1, 0],
    //   [0, 1, 0, 1, 0, 1, 0],
    //   [0, 1, 0, 1, 0, 0, 0],
    //   [0, 1, 1, 1, 1, 1, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],

    // LOWER_LEFT_RECT: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 1, 1, 1, 1, 0, 0],
    //   [0, 1, 1, 1, 1, 0, 0],
    //   [0, 1, 1, 1, 1, 0, 0],
    //   [0, 1, 1, 1, 1, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    // LOWER_RIGHT_RECT: [
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    //   [0, 0, 1, 1, 1, 1, 0],
    //   [0, 0, 1, 1, 1, 1, 0],
    //   [0, 0, 1, 1, 1, 1, 0],
    //   [0, 0, 1, 1, 1, 1, 0],
    //   [0, 0, 0, 0, 0, 0, 0],
    // ],
    FOUR_DOTS: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    TWO_DOTS: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    TWO_DOTS_CORNERS: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    TWO_DOTS_CORNERS_HEDGE: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 1, 1, 1, 1, 0],
      [0, 1, 0, 0, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 0, 0, 1, 0],
      [0, 1, 1, 1, 1, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    TWO_DOTS_REVERSE: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    TWO_DOTS_CORNERS_REVERSE: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 0, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 0, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    TWO_DOTS_CORNERS_HEDGE_REVERSE: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 0, 0],
      [0, 1, 1, 0, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 0, 1, 1, 0],
      [0, 0, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    U: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 0, 1, 0],
      [0, 1, 0, 1, 0, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    N: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 0, 1, 0, 1, 0],
      [0, 1, 0, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    C: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 0, 1, 1, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    REVERSE_C: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 1, 1, 0, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
    SMILE: [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 7, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 0, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ],
  };

  static ROOM_POOL = Object.keys(RoomGenerator.ROOM_TYPES);

  static chooseRoomType(room: Room) {
    const keys = RoomGenerator.ROOM_POOL.filter(
      (key: keyof typeof RoomGenerator.ROOM_TYPES) => {
        if (key === 'ARTIFACT_ROOM') {
          return false;
        }
        if (key === 'PUZZLE_ROOM') {
          return false;
        }
        if (key === 'HIDDEN_ROOM') {
          return false;
        }
        if (key === 'DEFAULT') {
          return false;
        }
        if (key === 'SMILE') {
          return false;
        }
        if (key === 'MINUS') {
          return (
            room.doors.length === 2 &&
            room.doors.includes('left') &&
            room.doors.includes('right')
          );
        }
        if (key === 'VERT_MINUS') {
          return (
            room.doors.length === 2 &&
            room.doors.includes('top') &&
            room.doors.includes('bottom')
          );
        }
        // if (key === 'LOWER_LEFT_RECT') {
        //   return (
        //     room.doors.length === 2
        //     && room.doors.includes('left')
        //     && room.doors.includes('bottom')
        //   );
        // }
        // if (key === 'LOWER_RIGHT_RECT') {
        //   return (
        //     room.doors.length === 2
        //     && room.doors.includes('right')
        //     && room.doors.includes('bottom')
        //   );
        // }
        // if (key === 'WINDING') {
        //   return (
        //     room.doors.length === 2
        //     && room.doors.includes('left')
        //     && room.doors.includes('top')
        //   );
        // }
        // if (key === 'WINDING_REVERSE') {
        //   return (
        //     room.doors.length === 2
        //     && room.doors.includes('right')
        //     && room.doors.includes('top')
        //   );
        // }
        // if (key === 'UP_LEFT_DOWN') {
        //   return (
        //     room.doors.length === 3
        //     && room.doors.includes('left')
        //     && room.doors.includes('top')
        //     && room.doors.includes('bottom')
        //   );
        // }
        // if (key === 'UP_RIGHT_DOWN') {
        //   return (
        //     room.doors.length === 3
        //     && room.doors.includes('right')
        //     && room.doors.includes('top')
        //     && room.doors.includes('bottom')
        //   );
        // }
        // if (key === 'LEFT_RIGHT_DOWN') {
        //   return (
        //     room.doors.length === 3
        //     && room.doors.includes('left')
        //     && room.doors.includes('right')
        //     && room.doors.includes('bottom')
        //   );
        // }
        if (
          [
            'PLUS',
            'PLUS_WITH_MISSING_CENTER',
            'HALLWAYS',
            'HALLWAYS_REVERSE',
            'HALLWAYS2',
            'HALLWAYS2_REVERSE',
          ].includes(key)
        ) {
          return room.doors.length === 4;
        }
        return true;
      },
    );
    if (keys.length === 0) {
      RoomGenerator.ROOM_POOL = Object.keys(RoomGenerator.ROOM_TYPES);
      RoomGenerator.chooseRoomType(room);
      return;
    }
    shuffleArray(keys);
    const roomTypeName = keys[0];
    room.roomType =
      room.roomType ||
      [...RoomGenerator.ROOM_TYPES[roomTypeName]].map(row => [...row]);

    if (room.doors.length === 4 && Phaser.Math.Between(0, 1)) {
      // transpose roomType
      room.roomType = room.roomType[0].map((col, i) =>
        room.roomType!.map(row => row[i]),
      );
    }

    room.roomTypeName =
      (Object.keys(RoomGenerator.ROOM_TYPES).find(
        key => RoomGenerator.ROOM_TYPES[key] === room.roomType,
      ) as keyof typeof RoomGenerator.ROOM_TYPES) || null;
    RoomGenerator.ROOM_POOL.splice(
      RoomGenerator.ROOM_POOL.indexOf(roomTypeName),
      1,
    );
  }

  static generate(room: Room, scene: Phaser.Scene) {
    for (let i = 0; i < room.roomType!.length; i += 1) {
      room.tiles[i] ||= [];
      for (let j = 0; j < room.roomType![i].length; j += 1) {
        let tileNumber = room.roomType![i][j];
        const originalTileNumber = tileNumber;

        const { value, stringRepresentation } =
          RoomGenerator.determineTileNumber(room, i, j, originalTileNumber);

        tileNumber = value;

        let tile: Phaser.GameObjects.Sprite;

        const autotilesetFrames = scene.textures
          .get('autotileset')
          .getFrameNames();
        const spriteSheetFrames = scene.textures
          .get('spritesheet')
          .getFrameNames();

        if (originalTileNumber === 7) {
          tile =
            room.tiles[i][j] ||
            scene.add.sprite(
              room.x + j * Dungeon.TILE_SIZE,
              room.y + i * Dungeon.TILE_SIZE,
              'spritesheet',
            );
          tile.setTexture('spritesheet', spriteSheetFrames[6]);
        } else {
          tile =
            room.tiles[i][j] ||
            scene.add.sprite(
              room.x + j * Dungeon.TILE_SIZE,
              room.y + i * Dungeon.TILE_SIZE,
              'autotileset',
              autotilesetFrames[tileNumber],
            );
          tile.setTexture('autotileset', autotilesetFrames[tileNumber]);
        }
        if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
          tile.setPipeline('Light2D');
        }

        room.tiles[i][j] = tile;
        room.tileNumbers[i] ||= [];
        room.tileNumbers[i][j] = stringRepresentation;
        tile.setTint(room.tint);
        if (originalTileNumber === 8) {
          tile.setData('targetAlpha', 0.85);
        } else {
          tile.setData('targetAlpha', 1);
        }
        tile.alpha = DEFAULT_ALPHA;
      }
    }
  }

  static MAPPING = {
    '0': 0,
    '2': 1,
    '8': 2,
    '10': 3,
    '11': 17,
    '16': 4,
    '18': 5,
    '22': 18,
    '24': 6,
    '26': 19,
    '27': 20,
    '30': 21,
    '31': 7,
    '64': 8,
    '66': 9,
    '72': 10,
    '74': 22,
    '75': 23,
    '80': 12,
    '82': 24,
    '86': 25,
    '88': 26,
    '104': 31,
    '106': 32,
    '107': 11,
    '120': 33,
    '208': 37,
    '210': 38,
    '214': 13,
    '216': 39,
    '248': 14,
    '255': 16,
  };

  private static determineTileNumber(
    room: Room,
    i: number,
    j: number,
    originalTileNumber: number,
  ) {
    let value = 0;
    const hasNorthernNeighbor = [0, 8].includes(room.roomType![i - 1]?.[j]);
    const hasWesternNeighbor = [0, 8].includes(room.roomType![i][j - 1]);
    const hasEasternNeighbor = [0, 8].includes(room.roomType![i][j + 1]);
    const hasSouthernNeighbor = [0, 8].includes(room.roomType![i + 1]?.[j]);

    const hasNorthernWesternNeighbor = [0, 8].includes(
      room.roomType![i - 1]?.[j - 1],
    );
    const hasNorthernEasternNeighbor = [0, 8].includes(
      room.roomType![i - 1]?.[j + 1],
    );
    const hasSouthernWesternNeighbor = [0, 8].includes(
      room.roomType![i + 1]?.[j - 1],
    );
    const hasSouthernEasternNeighbor = [0, 8].includes(
      room.roomType![i + 1]?.[j + 1],
    );

    const values: number[] = [];

    if (
      hasNorthernWesternNeighbor &&
      hasNorthernNeighbor &&
      hasWesternNeighbor
    ) {
      const valueToAdd = 1 * 2 ** 0;
      value += valueToAdd;
      values.push(valueToAdd);
    }

    if (hasNorthernNeighbor) {
      const valueToAdd = 1 * 2 ** 1;
      value += valueToAdd;
      values.push(valueToAdd);
    }

    if (
      hasNorthernEasternNeighbor &&
      hasNorthernNeighbor &&
      hasEasternNeighbor
    ) {
      const valueToAdd = 1 * 2 ** 2;
      value += valueToAdd;
      values.push(valueToAdd);
    }

    if (hasWesternNeighbor) {
      const valueToAdd = 1 * 2 ** 3;
      value += valueToAdd;
      values.push(valueToAdd);
    }

    if (hasEasternNeighbor) {
      const valueToAdd = 1 * 2 ** 4;
      value += valueToAdd;
      values.push(valueToAdd);
    }

    if (
      hasSouthernWesternNeighbor &&
      hasSouthernNeighbor &&
      hasWesternNeighbor
    ) {
      const valueToAdd = 1 * 2 ** 5;
      value += valueToAdd;
      values.push(valueToAdd);
    }

    if (hasSouthernNeighbor) {
      const valueToAdd = 1 * 2 ** 6;
      value += valueToAdd;
      values.push(valueToAdd);
    }

    if (
      hasSouthernEasternNeighbor &&
      hasSouthernNeighbor &&
      hasEasternNeighbor
    ) {
      const valueToAdd = 1 * 2 ** 7;
      value += valueToAdd;
      values.push(valueToAdd);
    }

    window.tileTypes.push(value);

    const stringRepresentation = values
      .reduce((acc, curr) => acc + curr, 0)
      .toString();

    // fill in all the floor tiles
    if ([1, 2, 3].includes(originalTileNumber)) {
      return {
        value: 15,
        stringRepresentation,
      };
    }
    return {
      value: this.MAPPING[value.toString()],
      stringRepresentation,
    };
  }
}
