import { format } from 'date-fns';
import Phaser from 'phaser';
import Collectible from './Collectible';
import Dungeon from './Dungeon';
import Room from './Room';
import { DEFAULT_ALPHA, start } from './util';

export default class PuzzleButton implements Collectible {
  sprite: Phaser.GameObjects.Image;

  tilePosition: number[];

  pathToKey: Room[] = [];

  destructionImminent: boolean;

  text: Phaser.GameObjects.Text;

  pressed: boolean;

  tween: Phaser.Tweens.Tween;

  pressedSprite = 5;

  releasedSprite = 6;

  frameNames: string[];

  addToScene(
    scene: Phaser.Scene,
    tilePosition: number[],
    textValue: string = '',
    releasedSprite: number = this.releasedSprite,
    pressedSprite: number = this.pressedSprite,
  ) {
    this.frameNames = scene.textures.get('spritesheet').getFrameNames();

    this.tilePosition = tilePosition;
    this.releasedSprite = releasedSprite;
    this.pressedSprite = pressedSprite;
    this.sprite = scene.add.image(
      this.tilePosition[1] * Dungeon.TILE_SIZE,
      this.tilePosition[0] * Dungeon.TILE_SIZE,
      'spritesheet',
    );
    this.release();
    if (textValue) {
      this.text = scene.add.text(
        (this.tilePosition[1] - 0.25) * Dungeon.TILE_SIZE,
        (this.tilePosition[0] - 0.5) * Dungeon.TILE_SIZE,
        textValue,
        { fontSize: '16px', fontFamily: 'Courier New' },
      );
      this.text.alpha = DEFAULT_ALPHA;
    }
    this.sprite.alpha = DEFAULT_ALPHA;
    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.sprite.setPipeline('Light2D');
    }
  }

  press() {
    this.sprite.setTexture('spritesheet', this.frameNames[this.pressedSprite]);
    this.pressed = true;
  }

  release() {
    this.sprite.setTexture('spritesheet', this.frameNames[this.releasedSprite]);
    this.pressed = false;
  }

  toggle() {
    if (this.pressed) {
      this.release();
    } else {
      this.press();
    }
  }

  update(time: number, delta: number): void {
    this.sprite.x +=
      (this.tilePosition[1] * Dungeon.TILE_SIZE - this.sprite.x) * delta * 0.02;
    this.sprite.y +=
      (this.tilePosition[0] * Dungeon.TILE_SIZE - this.sprite.y) * delta * 0.02;
  }
}
