/* eslint-disable no-use-before-define */
import Phaser, { Scene } from 'phaser';
import Dude from './Dude';
import Dungeon from './Dungeon';
import RoomGenerator from './RoomGenerator';
import { random, shuffleArray } from './util';

export type DoorLocations = 'top' | 'bottom' | 'left' | 'right';
const DOOR_LOCATION_MAPPING = {
  top: [0, 3],
  bottom: [6, 3],
  left: [3, 0],
  right: [3, 6],
};

interface RoomBehavior {
  onEnter?: (dude: Dude, dungeon: Dungeon) => void;
  update?: (dude: Dude, dungeon: Dungeon, scene: Scene) => void;
}

export default class Room {
  static ROOM_SIZE = 7;

  tiles: Phaser.GameObjects.Sprite[][];

  tileNumbers: string[][] = [];

  roomType: number[][] | null = RoomGenerator.ROOM_TYPES.DEFAULT;

  roomTypeName: keyof typeof RoomGenerator.ROOM_TYPES | null;

  x: number;

  y: number;

  doors: DoorLocations[];

  collectibles: Phaser.GameObjects.Image[] = [];

  static count = 0;

  id: number;

  tilePosition: any;

  tint: number;

  textValue: string;

  parent: Room;

  roomBehavior: RoomBehavior;

  aboveRoom: Room;

  belowRoom: Room;

  leftRoom: Room;

  rightRoom: Room;

  constructor(x: number, y: number, roomType: number[][] | null = null) {
    this.roomType = roomType ? [...roomType].map(row => [...row]) : null;
    this.roomTypeName =
      (Object.keys(RoomGenerator.ROOM_TYPES).find(
        key => RoomGenerator.ROOM_TYPES[key] === roomType,
      ) as keyof typeof RoomGenerator.ROOM_TYPES) || null;
    this.tiles = [];
    this.x = x;
    this.y = y;
    this.id = Room.count;
    Room.count += 1;
    this.tint = Room.generatePastelColor();
  }

  destroy() {
    this.tiles.forEach(row => row.forEach(tile => tile.destroy()));
  }

  pickRandomDoor(): [string, number[]] {
    const filteredDoors = this.doors;
    shuffleArray(filteredDoors);
    return [filteredDoors[0], DOOR_LOCATION_MAPPING[filteredDoors[0]]];
  }

  pickRandomPosition(omitCorners = false): [number, number] {
    const slots = [
      [1, 2],
      [1, 4],
      [2, 1],
      [2, 2],
      [2, 3],
      [2, 4],
      [2, 5],
      [3, 2],
      [3, 3],
      [3, 4],
      //[4, 1],
      [4, 2],
      [4, 3],
      [4, 4],
      [4, 5],
      [5, 2],
      [5, 4],
    ].filter(slot => this.roomType![slot[0]][slot[1]] === 1);
    shuffleArray(slots);
    return slots[0] as [number, number];
  }

  pickRandomCorner(): [number, number] {
    const corners = [
      [1, 5],
      [5, 1],
      [5, 5],
      [1, 1],
    ];
    shuffleArray(corners);
    return corners.filter(
      corner => this.roomType![corner[0]]?.[corner[1]] === 1,
    )[0] as [number, number];
  }

  private static generatePastelColor(mix = [255, 255, 255]): number {
    let r = random.between(0, 255);
    let g = random.between(0, 255);
    let b = random.between(0, 255);

    r = (r + mix[0]) / 2;
    g = (g + mix[1]) / 2;
    b = (b + mix[2]) / 2;

    return Phaser.Display.Color.GetColor(r, g, b);
  }
}
