import { format } from 'date-fns';
import Phaser from 'phaser';
import Collectible from './Collectible';
import Dungeon from './Dungeon';
import Room from './Room';
import { DEFAULT_ALPHA, start } from './util';

export default class Fork implements Collectible {
  sprite: Phaser.GameObjects.Image;

  tilePosition: number[];

  pathToKey: Room[] = [];

  destructionImminent: boolean;

  planted: boolean;

  addToScene(scene: Phaser.Scene, tilePosition: number[]) {
    this.tilePosition = tilePosition;
    this.sprite = scene.add.image(
      this.tilePosition[1] * Dungeon.TILE_SIZE,
      this.tilePosition[0] * Dungeon.TILE_SIZE,
      'spritesheet',
      scene.textures.get('spritesheet').getFrameNames()[13],
    );
    this.sprite.alpha = DEFAULT_ALPHA;
    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.sprite.setPipeline('Light2D');
    }
  }

  update(time: number, delta: number): void {
    this.sprite.x +=
      (this.tilePosition[1] * Dungeon.TILE_SIZE - this.sprite.x) * delta * 0.02;
    this.sprite.y +=
      (this.tilePosition[0] * Dungeon.TILE_SIZE - this.sprite.y) * delta * 0.02;
  }
}
