/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { mergeStyles } from '@fluentui/merge-styles';
import { isMobile } from '../util';
import useStore from './useStore';

const ThankYouDialog: FC = () => {
  const thankYouDialogOpen = useStore(store => store.thankYouDialogOpen);
  const setThankYouDialogOpen = useStore(store => store.setThankYouDialogOpen);

  return (
    <Dialog
      open={thankYouDialogOpen}
      classes={{
        paper: mergeStyles({
          width: isMobile() ? '90vw' : '70vw',
          height: isMobile() ? 950 : 700,
          fontSize: isMobile() ? 48 : 20,
        }),
      }}
    >
      <DialogTitle
        style={{
          fontSize: isMobile() ? 48 : 32,
          paddingLeft: isMobile() ? 36 : 20,
          paddingTop: isMobile() ? 24 : 20,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>Thank You</div>
      </DialogTitle>
      <DialogContent
        style={{
          paddingLeft: isMobile() ? 36 : 20,
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        Today marks day 500 of Daily Dungeon. If you're still here after all
        this time, I thank you for playing Daily Dungeon until now and hope you
        continue to enjoy it in the future! Please find me on Threads (
        <a
          style={{ color: 'white' }}
          target="_blank"
          rel="noreferrer"
          href="https://threads.net/hi.im.vijay"
        >
          @hi.im.vijay
        </a>
        ) and let me know what you think of the game and what you'd like to see
        in the future. I'm always looking for feedback and ideas to improve the
        game!
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
          marginLeft: 'auto',
        }}
      >
        <Button
          size="large"
          style={{
            fontSize: isMobile() ? 32 : 20,
          }}
          variant="contained"
          onClick={() => setThankYouDialogOpen(false)}
        >
          Play
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ThankYouDialog;
