/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { mergeStyles } from '@fluentui/merge-styles';
import { isMobile } from '../util';
import useStore from './useStore';

const HelpDialog: FC = () => {
  const helpDialogOpen = useStore(store => store.helpDialogOpen);
  const setHelpDialogOpen = useStore(store => store.setHelpDialogOpen);

  return (
    <Dialog
      fullScreen
      open={helpDialogOpen}
      classes={{
        paper: mergeStyles({
          width: isMobile() ? '90vw' : '50vw',
          height: isMobile() ? 950 : 500,
          fontSize: isMobile() ? 48 : 20,
        }),
      }}
    >
      <DialogTitle
        style={{
          fontSize: isMobile() ? 48 : 32,
          paddingLeft: isMobile() ? 36 : 20,
          paddingTop: isMobile() ? 24 : 20,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>How to Play</div>
      </DialogTitle>
      <DialogContent
        style={{
          paddingLeft: isMobile() ? 36 : 20,
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: isMobile() ? 36 : 36,
            alignItems: 'center',
          }}
        >
          <div
            style={{
              paddingLeft: isMobile() ? 25 : 12,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h6"
              style={{ paddingRight: isMobile() ? 10 : 12 }}
            >
              Move with {!isMobile() && 'the WASD/← ↑ → ↓ keys'}
            </Typography>
            {isMobile() && (
              <>
                <img src="./left.png" width="48" height="48" alt="left" />
                <img src="./up.png" width="48" height="48" alt="up" />
                <img src="./right.png" width="48" height="48" alt="right" />
                <img src="./down.png" width="48" height="48" alt="down" />
              </>
            )}
          </div>
          <img src="./movement.gif" alt="movement" />
        </div>
        <Divider />
        <div
          style={{
            display: 'flex',
            width: '100%',
            paddingTop: isMobile() ? 36 : 36,
            paddingBottom: isMobile() ? 36 : 36,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src="./keys.gif" alt="keys" />
          <div
            style={{
              paddingLeft: isMobile() ? 25 : 12,
              paddingRight: isMobile() ? 25 : 12,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Collect keys and unlock doors</Typography>
          </div>
          <img src="./locks.gif" alt="locks" />
        </div>
        <Divider />
        <div
          style={{
            display: 'flex',
            width: '100%',
            paddingTop: isMobile() ? 36 : 36,
            paddingBottom: isMobile() ? 36 : 36,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src="./puzzles.gif" alt="puzzles" />
          <div
            style={{
              paddingLeft: isMobile() ? 25 : 12,
              paddingRight: isMobile() ? 25 : 12,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">
              Solve puzzles and collect artifacts
            </Typography>
          </div>
          <img src="./artifacts.gif" alt="movement" />
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
          marginLeft: 'auto',
        }}
      >
        <Button
          size="large"
          style={{
            fontSize: isMobile() ? 32 : 20,
          }}
          variant="contained"
          onClick={() => setHelpDialogOpen(false)}
        >
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpDialog;
