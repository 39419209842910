import useStore from '../react/useStore';
import Room from '../Room';
import TextTile from '../TextTile';
import { DEFAULT_ALPHA, shuffleArray } from '../util';
import Puzzle from './Puzzle';

export default class SlidePuzzle extends Puzzle {
  generate(): void {
    const textTilePositions: number[][][] = [
      [
        [2, 2],
        [2, 3],
        [2, 4],
      ],
      [
        [3, 2],
        [3, 3],
        [3, 4],
      ],
      [
        [4, 2],
        [4, 3],
        [4, 4],
      ],
    ];
    const slidePuzzle: TextTile[][] = [];
    const numbers = [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8],
    ];
    const originalNumbers = [...numbers];
    for (let i = 0; i < 3; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        const textTile = new TextTile();
        textTile.addToScene(
          this.scene,
          [
            this.room.tilePosition[0] * Room.ROOM_SIZE -
              0.5 +
              textTilePositions[i][j][0],
            this.room.tilePosition[1] * Room.ROOM_SIZE -
              0.5 +
              textTilePositions[i][j][1],
          ],
          i === 2 && j > 1 ? '' : numbers[i][j].toString(),
        );
        textTile.text.setDepth(1);
        textTile.text.alpha = DEFAULT_ALPHA;
        slidePuzzle[i] = slidePuzzle[i] || [];
        slidePuzzle[i][j] = textTile;
      }
    }

    let blankSpace = [2, 2];

    // shuffle slide puzzle
    for (let i = 0; i < 100; i += 1) {
      const availableMoves: number[][] = [];
      if (blankSpace[0] > 0) {
        availableMoves.push([blankSpace[0] - 1, blankSpace[1]]);
      }
      if (blankSpace[0] < 2) {
        availableMoves.push([blankSpace[0] + 1, blankSpace[1]]);
      }
      if (blankSpace[1] > 0) {
        availableMoves.push([blankSpace[0], blankSpace[1] - 1]);
      }
      if (blankSpace[1] < 2) {
        availableMoves.push([blankSpace[0], blankSpace[1] + 1]);
      }
      shuffleArray(availableMoves);
      const randomMove = availableMoves[0];
      slidePuzzle[blankSpace[0]][blankSpace[1]].text.text =
        slidePuzzle[randomMove[0]][randomMove[1]].text.text;
      slidePuzzle[randomMove[0]][randomMove[1]].text.text = '';
      blankSpace = randomMove;
    }

    const { dude } = this.scene;

    let presses = 0;

    let previouslyPressed: TextTile | null = null;
    let solved = false;
    dude.sprite.on('move', () => {
      if (this.completed) {
        this.revealTheWorld();
        return;
      }
      if (solved) {
        return;
      }
      slidePuzzle
        .flat()
        .forEach(textTile =>
          textTile.text.setAlpha(
            this.room === this.getCurrentRoom()
              ? 1
              : this.room.tiles.flat()[0].alpha,
          ),
        );
      const pressedButton = slidePuzzle
        .flat()
        .find(
          thing =>
            Math.ceil(thing.tilePosition[0]) === dude.tilePosition[0] &&
            Math.ceil(thing.tilePosition[1]) === dude.tilePosition[1],
        );
      if (pressedButton) {
        if (!previouslyPressed) {
          previouslyPressed = pressedButton;
        } else if (pressedButton.text.text === '') {
          pressedButton.text.text = previouslyPressed.text.text;
          previouslyPressed.text.text = '';
          previouslyPressed = pressedButton;
        } else {
          previouslyPressed = pressedButton;
        }
        presses += 1;
        if (presses >= 100) {
          useStore.getState().setNoMistakes(false);
        }
      } else {
        previouslyPressed = null;
      }
      if (
        slidePuzzle
          .flat()
          .map(textTile => textTile.text.text)
          .join('') === originalNumbers.flat().join('')
      ) {
        solved = true;
        slidePuzzle.flat().forEach((textTile, index) => {
          this.scene.time.addEvent({
            delay: 100 * index,
            callback: () => {
              textTile.text.tint = 0x00ff00;
            },
          });
        });
        this.scene.time.addEvent({
          delay: 100 * 8 + 300,
          callback: () => {
            slidePuzzle.flat().forEach(tile => tile.text.destroy());
            this.completed = true;
            this.solveSuperHiddenPuzzle();
            useStore.getState().setSuperHiddenPuzzleSolved(true);
          },
        });
      }
    });
  }
}
