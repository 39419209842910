import { format } from 'date-fns';
import Phaser from 'phaser';
import Collectible from './Collectible';
import Dungeon from './Dungeon';
import useStore from './react/useStore';
import Room from './Room';
import Main from './scenes/Main';
import { DEFAULT_ALPHA, start } from './util';

export default class WarpButton implements Collectible {
  sprite: Phaser.GameObjects.Image;

  tilePosition: number[];

  pressed: boolean;

  warpSprite: Phaser.GameObjects.Sprite;

  onPress: () => void;

  frameNames: string[];

  addToScene(scene: Main, tilePosition: number[], room: Room) {
    this.frameNames = scene.textures.get('spritesheet').getFrameNames();
    this.tilePosition = tilePosition;
    this.sprite = scene.add.image(
      this.tilePosition[1] * Dungeon.TILE_SIZE,
      this.tilePosition[0] * Dungeon.TILE_SIZE,
      'spritesheet',
    );
    this.release();
    this.warpSprite = scene.add.sprite(
      this.tilePosition[1] * Dungeon.TILE_SIZE,
      this.tilePosition[0] * Dungeon.TILE_SIZE,
      'spritesheet',
      scene.textures.get('spritesheet').getFrameNames()[10],
    );
    this.sprite.setDepth(1);
    this.sprite.alpha = DEFAULT_ALPHA;
    this.warpSprite.setDepth(1);
    this.warpSprite.alpha = DEFAULT_ALPHA * 0.5;
    this.warpSprite.scale = 0.5;
    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.sprite.setPipeline('Light2D');
      this.warpSprite.setPipeline('Light2D');
    }
    scene.updaters.push(() => {
      if (this.pressed) {
        this.warpSprite.tint = 0xff22aa;
      }
    });
    scene.dude.sprite.on('move', (previousPosition, currentPosition) => {
      const alpha =
        room ===
        scene.dungeon.rooms[
          Math.floor(scene.dude.tilePosition[0] / Room.ROOM_SIZE)
        ]?.[Math.floor(scene.dude.tilePosition[1] / Room.ROOM_SIZE)]
          ? 1
          : room.tiles.flat()[0].alpha;
      this.sprite.setAlpha(alpha);
      this.warpSprite.setAlpha(alpha * 0.5);
      if (
        currentPosition[0] === this.tilePosition[0] &&
        currentPosition[1] === this.tilePosition[1]
      ) {
        scene.cameras.main.shake(500, 0.0005);
        useStore.getState().setActivatedPortals(true);
        this.press();
        this.onPress?.();
      }
    });
    this.sprite.alpha = DEFAULT_ALPHA;
  }

  press() {
    this.sprite.setTexture('spritesheet', this.frameNames[5]);
    this.warpSprite.play('warp-idle');
    this.pressed = true;
  }

  release() {
    this.sprite.setTexture('spritesheet', this.frameNames[6]);
    this.pressed = false;
  }

  toggle() {
    if (this.pressed) {
      this.release();
    } else {
      this.press();
    }
  }

  update(time: number, delta: number): void {
    this.sprite.x +=
      (this.tilePosition[1] * Dungeon.TILE_SIZE - this.sprite.x) * delta * 0.02;
    this.sprite.y +=
      (this.tilePosition[0] * Dungeon.TILE_SIZE - this.sprite.y) * delta * 0.02;
  }
}
