import Dungeon from './Dungeon';
import Room from './Room';
import Main from './scenes/Main';
import { shuffleArray } from './util';
import SimonSays from './puzzles/SimonSays';
import Puzzle from './puzzles/Puzzle';
import Memory from './puzzles/Memory';
import MathPuzzle from './puzzles/MathPuzzle';
import WordPuzzle from './puzzles/WordPuzzle';
// import Lights from './puzzles/Lights';
import Path from './puzzles/Path';
import Shapes from './puzzles/Shapes';
import Path2 from './puzzles/Path2';
import Shapes2 from './puzzles/Shapes2';
// import Lights from './puzzles/Lights';

class PuzzleGenerator {
  room: Room;

  dungeon: Dungeon;

  scene: Main;

  static PUZZLE_TYPES: typeof Puzzle[] = [
    SimonSays,
    WordPuzzle,
    MathPuzzle,
    Memory,
    // Lights,
    Path,
    Path2,
    Shapes2,
  ];

  completed: boolean;

  constructor(room: Room, dungeon: Dungeon, scene: Main) {
    this.room = room;
    this.dungeon = dungeon;
    this.scene = scene;
    this.completed = false;
    shuffleArray(PuzzleGenerator.PUZZLE_TYPES);
  }

  generate() {
    const PuzzleImpl = PuzzleGenerator.PUZZLE_TYPES.pop();
    new PuzzleImpl!(this.room, this.dungeon, this.scene).generate();
  }
}

export default PuzzleGenerator;
