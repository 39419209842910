import Artifact from '../Artifact';
import Dungeon from '../Dungeon';
import Fork from '../Fork';
import Room from '../Room';
import Main from '../scenes/Main';

export default class Puzzle {
  completed: boolean = false;

  room: Room;

  dungeon: Dungeon;

  scene: Main;

  constructor(room: Room, dungeon: Dungeon, scene: Main) {
    this.room = room;
    this.dungeon = dungeon;
    this.scene = scene;
  }

  generate(): void {
    throw new Error('Method not implemented.');
  }

  protected addArtifact() {
    if (this.completed) {
      return;
    }
    this.completed = true;
    const artifact = new Artifact();
    this.dungeon.artifacts.push(artifact);
    artifact.addToScene(this.scene, [
      this.room.tilePosition[0] * Room.ROOM_SIZE + 3,
      this.room.tilePosition[1] * Room.ROOM_SIZE + 3,
    ]);
  }

  protected getCurrentRoom() {
    return this.dungeon.rooms[
      Math.floor(this.scene.dude.tilePosition[0] / Room.ROOM_SIZE)
    ]?.[Math.floor(this.scene.dude.tilePosition[1] / Room.ROOM_SIZE)];
  }

  protected solveSuperHiddenPuzzle() {
    const fork = new Fork();
    fork.addToScene(this.scene, [
      this.room.tilePosition[0] * Room.ROOM_SIZE + 3,
      this.room.tilePosition[1] * Room.ROOM_SIZE + 3,
    ]);
    this.dungeon.fork = fork;
  }

  protected revealTheWorld() {
    this.dungeon.keys.forEach(key => {
      key.sprite.alpha = 1;
    });
    this.dungeon.locks.forEach(lock => {
      lock.sprite.alpha = 1;
      lock.adjacentSprite.alpha = 1;
    });
    this.dungeon.artifacts.forEach(artifact => {
      artifact.sprite.alpha = 1;
    });
    this.dungeon.rooms
      .flat()
      .filter(
        room =>
          room.roomTypeName !== 'TOKEN_ROOM' &&
          room.roomTypeName !== 'CAKE_ROOM' &&
          room.roomTypeName !== 'HIDDEN_ROOM',
      )
      .forEach(room =>
        room.tiles.flat().forEach(tile => {
          tile.alpha = 1;
        }),
      );
    this.dungeon.planter.sprite.alpha = 1;
    this.dungeon.seed.sprite.alpha = 1;
    this.dungeon.warpTiles.forEach(warpTile => {
      warpTile.sprite.alpha = 1;
    });
  }
}
