import React, { FC } from 'react';
import { render } from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { isMobile } from '../util';
import CompletedDialog from './CompletedDialog';
import Options from './Options';
import HelpDialog from './HelpDialog';
import ThankYouDialog from './ThankYouDialog';
import useStore from './useStore';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Courier New',
    fontSize: isMobile() ? 32 : 16,
    h6: {
      fontSize: isMobile() ? '42px' : '24px',
    },
    caption: {
      fontSize: isMobile() ? '32px' : '12px',
      lineHeight: isMobile() ? '32px' : '16px',
    },
  },
});

const Root: FC = () => {
  const demoMode = useStore(store => store.demoMode);
  return (
  <ThemeProvider theme={darkTheme}>
    <CompletedDialog />
    <HelpDialog />
    <ThankYouDialog />
    {!demoMode && <Options />}
  </ThemeProvider>
  );
}

render(<Root />, document.getElementById('ui'));
