import qrcode from 'qrcode';
import Phaser from 'phaser';
import { shuffleArray } from './util';
import useStore from './react/useStore';

export default class Preloader {
  static preload(scene: Phaser.Scene) {
    scene.load.atlas({
      key: 'spritesheet',
      textureURL: '20220531objects.png',
      atlasURL: 'colored_tilemap_packed.json',
    });
    scene.load.atlas({
      key: 'autotileset',
      textureURL: '20220531tiles-sheet.png',
      atlasURL: '20220531tiles.json',
      normalMap: 'autotileset_n.png',
    });
    scene.load.spritesheet('dude', '20220531dude-Sheet.png', {
      frameWidth: 16,
      frameHeight: 16,
    });
    scene.load.spritesheet('warp', '20220531warp-Sheet.png', {
      frameWidth: 16,
      frameHeight: 16,
      spacing: 1,
    });
    scene.load.spritesheet('planter', '20220531pot-Sheet.png', {
      frameWidth: 16,
      frameHeight: 16,
      spacing: 1,
    });
    scene.load.spritesheet('flower', '20220531flowr-Sheet.png', {
      frameWidth: 16,
      frameHeight: 16,
      spacing: 1,
    });
    scene.load.spritesheet('relic00', '20220531relic00-Sheet.png', {
      frameWidth: 16,
      frameHeight: 16,
      spacing: 1,
    });
    scene.load.spritesheet('relic01', '20220531relic01-Sheet.png', {
      frameWidth: 16,
      frameHeight: 16,
      spacing: 1,
    });
    scene.load.spritesheet('relic02', '20220531relic02-Sheet.png', {
      frameWidth: 16,
      frameHeight: 16,
      spacing: 1,
    });
    scene.load.image('particle', 'particle.png');
    scene.load.scenePlugin('rexgesturesplugin', 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexgesturesplugin.min.js', 'rexGestures', 'rexGestures');

    const directions = ['up', 'down', 'left', 'right'];
    const directionsForPath: string[] = [];
    for (let i = 0; i < 8; i += 1) {
      shuffleArray(directions);
      directionsForPath.push(directions[0]);
    }
    useStore.getState().setDirectionsForPath(directionsForPath);
    qrcode.toDataURL(
      directionsForPath.join('-'),
      {
        scale: 2,
        color: {
          dark: '#FFF', // Blue dots
          light: '#0000', // Transparent background
        },
      },
      (err, url) => {
        scene.textures.addBase64('qrcode', url);
      },
    );
  }
}
