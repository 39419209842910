import mexp from 'math-expression-evaluator';
import useStore from '../react/useStore';
import Room from '../Room';
import TextTile from '../TextTile';
import { DEFAULT_ALPHA, random, shuffleArray } from '../util';
import Puzzle from './Puzzle';

export default class MathPuzzle extends Puzzle {
  generate(): void {
    const textTilePositions: number[][] = [
      [1, 1],
      [1, 2],
      [1, 3],
      [1, 4],
      [1, 5],
    ];
    const firstNumber = random.between(1, 8);
    const operator = random.between(0, 1) ? '+' : '-';
    const secondNumber = random.between(1, 10 - firstNumber - 1);
    const expression = [
      firstNumber,
      operator,
      secondNumber,
      '=',
      operator === '+'
        ? firstNumber + secondNumber
        : Math.abs(firstNumber - secondNumber),
    ];
    const textTiles: TextTile[] = [];
    shuffleArray(expression);
    while (expression[1] === '=' || expression[3] === '=') {
      shuffleArray(expression);
    }
    for (let i = 0; i < textTilePositions.length; i += 1) {
      const textTile = new TextTile();
      textTile.addToScene(
        this.scene,
        [
          this.room.tilePosition[0] * Room.ROOM_SIZE -
            0.5 +
            textTilePositions[i][0],
          this.room.tilePosition[1] * Room.ROOM_SIZE -
            0.5 +
            textTilePositions[i][1],
        ],
        expression[i].toString(),
      );
      textTile.text.setDepth(1);
      textTile.text.alpha = DEFAULT_ALPHA;
      textTiles.push(textTile);
    }

    const { dude } = this.scene;

    let swaps = 0;

    let previouslyPressed: TextTile | null = null;
    const solved = false;
    let justSteppedOnATextTile = false;
    let justSlid = false;

    dude.sprite.on('move', () => {
      if (solved) {
        return;
      }
      if (this.completed) {
        return;
      }

      textTiles.forEach(textTile =>
        textTile.text.setAlpha(
          this.room === this.getCurrentRoom()
            ? 1
            : this.room.tiles.flat()[0].alpha,
        ),
      );
      textTiles.forEach(textTile => textTile.text.setTint(0xffffff));
      const pressedTextTile = textTiles.find(
        thing =>
          Math.ceil(thing.tilePosition[0]) === dude.tilePosition[0] &&
          Math.ceil(thing.tilePosition[1]) === dude.tilePosition[1],
      );
      if (pressedTextTile) {
        if (previouslyPressed) {
          // swap the two
          const temp = pressedTextTile.text.text;
          pressedTextTile.text.text = previouslyPressed.text.text;
          previouslyPressed.text.text = temp;
          if (justSteppedOnATextTile) {
            previouslyPressed = pressedTextTile;
            justSlid = true;
          } else {
            previouslyPressed = null;
            justSlid = false;
          }
          swaps += 1;
        } else {
          previouslyPressed = pressedTextTile;
        }
        justSteppedOnATextTile = true;
      } else {
        if (justSlid) {
          justSlid = false;
          previouslyPressed = null;
          textTiles.forEach(textTile => textTile.text.setTint(0xffffff));
        }
        justSteppedOnATextTile = false;
      }

      if (swaps > 10) {
        useStore.getState().setPerfectGame(false);
      }

      if (previouslyPressed) {
        previouslyPressed.text.tint = 0xaaaa00;
      }

      if (textTiles[3].text.text === '=') {
        let parsed;
        try {
          parsed = parseInt(
            mexp.eval(
              textTiles[0].text.text +
                textTiles[1].text.text +
                textTiles[2].text.text,
            ),
            10,
          );
        } catch (e) {
          parsed = -1;
        }
        if (parsed === parseInt(textTiles[4].text.text, 10)) {
          textTiles.forEach(textTile => textTile.text.setTint(0x00ff00));
          this.scene.time.addEvent({
            delay: 1000,
            callback: () => {
              textTiles.forEach(textTile => textTile.text.setAlpha(0));
              this.completed = true;
            },
          });
          this.addArtifact();
        }
      }
      if (textTiles[1].text.text === '=') {
        let parsed;
        try {
          parsed = parseInt(
            mexp.eval(
              textTiles[2].text.text +
                textTiles[3].text.text +
                textTiles[4].text.text,
            ),
            10,
          );
        } catch (e) {
          parsed = -1;
        }
        if (parsed === parseInt(textTiles[0].text.text, 10)) {
          textTiles.forEach(textTile => textTile.text.setTint(0x00ff00));
          this.scene.time.addEvent({
            delay: 1000,
            callback: () => {
              textTiles.forEach(textTile => textTile.text.setAlpha(0));
              this.completed = true;
            },
          });
          this.addArtifact();
        }
      }
    });
  }
}
