import useStore from '../react/useStore';
import Room from '../Room';
import TextTile from '../TextTile';
import { DEFAULT_ALPHA, shuffleArray } from '../util';
import Puzzle from './Puzzle';

export default class HiddenRoomPuzzle extends Puzzle {
  generate(): void {
    const questionMark = new TextTile();
    questionMark.addToScene(
      this.scene,
      [
        this.room.tilePosition[0] * Room.ROOM_SIZE - 0.5 + 3,
        this.room.tilePosition[1] * Room.ROOM_SIZE - 0.5 + 3,
      ],
      '?',
    );
    questionMark.text.setDepth(1);
    questionMark.text.alpha = DEFAULT_ALPHA;

    const { dude } = this.scene;

    this.scene.updaters.push((time, delta) => {
      questionMark.update(time, delta);
    });

    let acquisitions = 0;

    dude.sprite.on('move', () => {
      if (this.completed) {
        return;
      }
      const questionMarkRoom =
        this.dungeon.rooms[
          Math.floor(questionMark.tilePosition[0] / Room.ROOM_SIZE)
        ][Math.floor(questionMark.tilePosition[1] / Room.ROOM_SIZE)];
      questionMark.text.setAlpha(
        questionMarkRoom === this.getCurrentRoom()
          ? 1
          : questionMarkRoom.tiles.flat()[0].alpha,
      );
      if (
        Math.ceil(questionMark.tilePosition[0]) === dude.tilePosition[0] &&
        Math.ceil(questionMark.tilePosition[1]) === dude.tilePosition[1]
      ) {
        const rooms = this.dungeon.rooms
          .flat()
          .filter(Boolean)
          .filter(
            room =>
              room !== this.room &&
              room.roomTypeName !== 'CAKE_ROOM' &&
              room.roomTypeName !== 'SUPER_HIDDEN_ROOM' &&
              room.roomTypeName !== 'SMILE' &&
              room.roomTypeName !== 'TOKEN_ROOM',
          );
        shuffleArray(rooms);
        const room = rooms[0];
        const randomPosition = room.pickRandomPosition();
        if (acquisitions < 3) {
          acquisitions += 1;
          questionMark.tilePosition = [
            room.tilePosition[0] * Room.ROOM_SIZE - 0.5 + randomPosition[0],
            room.tilePosition[1] * Room.ROOM_SIZE - 0.5 + randomPosition[1],
          ];
          const questionMarkMewRoom =
            this.dungeon.rooms[
              Math.floor(questionMark.tilePosition[0] / Room.ROOM_SIZE)
            ][Math.floor(questionMark.tilePosition[1] / Room.ROOM_SIZE)];
          const alpha =
            questionMarkMewRoom === this.getCurrentRoom()
              ? 1
              : questionMarkMewRoom.tiles.flat()[0].alpha;
          this.scene.add.tween({
            targets: questionMark.text,
            duration: 500,
            alpha,
          });
        } else {
          questionMark.tilePosition = [
            questionMark.tilePosition[0] - 5,
            questionMark.tilePosition[1],
          ];
          questionMark.text.tint = 0x0ff00;
          this.scene.add.tween({
            targets: questionMark.text,
            duration: 500,
            alpha: 0,
            onComplete: () => {
              this.scene.updaters.pop();
              this.completed = true;
              questionMark.text.destroy();
              useStore.getState().setHiddenPuzzleSolved(true);
            },
          });
        }
      }
    });
  }
}
