import PuzzleButton from '../PuzzleButton';
import useStore from '../react/useStore';
import Room from '../Room';
import TextTile from '../TextTile';
import { DEFAULT_ALPHA, shuffleArray } from '../util';
import Puzzle from './Puzzle';

const SHAPES = '■▲◆♥●'.split('');

export default class Shapes2 extends Puzzle {
  generate(): void {
    const textTilePositions: number[][] = [
      [1, 1],
      [1, 2],
      [1, 3],
      [1, 4],
      [1, 5],
    ];
    let shuffledShapes = '■■■■■▲▲▲▲▲◆◆◆◆◆♥♥♥♥♥●●●●●'.split('');
    shuffleArray(shuffledShapes);
    shuffledShapes = shuffledShapes.slice(0, 5);
    const shuffledWords = [shuffledShapes.join('')];
    shuffleArray(shuffledWords);
    const expression = '■■■■■'.split('');
    const textTiles: TextTile[] = [];
    shuffleArray(expression);
    while (shuffledWords.includes(expression.join(''))) {
      shuffleArray(expression);
    }
    for (let i = 0; i < textTilePositions.length; i += 1) {
      const textTile = new TextTile();
      textTile.addToScene(
        this.scene,
        [
          this.room.tilePosition[0] * Room.ROOM_SIZE -
            0.5 +
            textTilePositions[i][0],
          this.room.tilePosition[1] * Room.ROOM_SIZE -
            0.5 +
            textTilePositions[i][1],
        ],
        expression[i].toString(),
      );
      textTile.text.setDepth(1);
      textTile.text.alpha = DEFAULT_ALPHA;
      textTiles.push(textTile);
    }

    const { dude } = this.scene;

    let swaps = 0;

    let previouslyPressed: TextTile | null = null;
    const solved = false;
    let justSteppedOnATextTile = false;
    let justSlid = false;

    const submitButton = new PuzzleButton();
    submitButton.addToScene(
      this.scene,
      [
        this.room.tilePosition[0] * Room.ROOM_SIZE + 3,
        this.room.tilePosition[1] * Room.ROOM_SIZE + 3,
      ],
      ' ',
    );
    submitButton.text.alpha = DEFAULT_ALPHA;

    const isShowingResults = false;
    dude.sprite.on('move', () => {
      if (solved) {
        return;
      }
      if (this.completed) {
        return;
      }

      let texts: Phaser.GameObjects.Text[] = [];

      if (
        dude.tilePosition[0] === submitButton.tilePosition[0] &&
        dude.tilePosition[1] === submitButton.tilePosition[1] &&
        !submitButton.pressed
      ) {
        submitButton.press();
        textTiles.forEach((textTile, index) => {
          let color;
          let text;
          if (shuffledShapes.includes(textTile.text.text)) {
            if (textTile.text.text === shuffledShapes[index]) {
              color = 0x5ba300;
              text = '✔';
            } else if (
              shuffledShapes.includes(textTile.text.text) &&
              shuffledShapes.filter(shape => shape === textTile.text.text)
                .length >
                textTiles.filter(
                  (_t, index) =>
                    shuffledShapes[index] === textTiles[index].text.text && textTiles[index].text.text === textTile.text.text,
                ).length
            ) {
              color = 0x0073e6;
              text = '●';
            } else {
              color = 0xb51963;
              text = '✘';
            }
          } else {
            color = 0xb51963;
            text = '✘';
          }
          this.scene.tweens.add({
            targets: textTile.text,
            tint: color,
            duration: index * 300,
          });

          this.scene.time.addEvent({
            delay: index * 300,
            callback: () => {
              const textItem = this.scene.add.text(
                textTile.text.x + 4,
                textTile.text.y + 4,
                text,
                {
                  fontFamily: 'Arial',
                  fontSize: '16px',
                  color: '#ffffff',
                },
              );
              textItem.setDepth(1);
              textItem.setScale(0.5);
              texts.push(textItem);
            },
          });
        });
        this.scene.time.addEvent({
          delay: textTiles.length * 300 + 500,
          callback: () => {
            textTiles.forEach((textTile, index) => {
              textTile.text.setTint(0xffffff);
            });
            texts.forEach(text => text.destroy());
            submitButton.release();
          },
        });
      }

      textTiles.forEach(textTile =>
        textTile.text.setAlpha(
          this.room === this.getCurrentRoom()
            ? 1
            : this.room.tiles.flat()[0].alpha,
        ),
      );
      submitButton.sprite.setAlpha(
        this.room === this.getCurrentRoom()
          ? 1
          : this.room.tiles.flat()[0].alpha,
      );
      // textTiles.forEach(textTile => textTile.text.setTint(0xffffff));
      const pressedTextTile = textTiles.find(
        thing =>
          Math.ceil(thing.tilePosition[0]) === dude.tilePosition[0] &&
          Math.ceil(thing.tilePosition[1]) === dude.tilePosition[1],
      );
      if (pressedTextTile) {
        const index = SHAPES.indexOf(pressedTextTile.text.text);
        pressedTextTile.text.text = SHAPES[(index + 1) % SHAPES.length];
      } else {
        if (justSlid) {
          justSlid = false;
          previouslyPressed = null;
          textTiles.forEach(textTile => textTile.text.setTint(0xffffff));
        }
        justSteppedOnATextTile = false;
      }

      if (swaps > 10) {
        useStore.getState().setPerfectGame(false);
      }

      if (previouslyPressed) {
        previouslyPressed.text.tint = 0xaaaa00;
      }

      if (
        shuffledWords.some(
          word =>
            word === textTiles.map(textTile => textTile.text.text).join(''),
        )
      ) {
        textTiles.forEach(textTile => textTile.text.setTint(0x00ff00));
        this.scene.time.addEvent({
          delay: 1000,
          callback: () => {
            textTiles.forEach(textTile => textTile.text.setAlpha(0));
            this.completed = true;
          },
        });
        this.addArtifact();
      }
    });
  }
}
