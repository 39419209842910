import create, { GetState, SetState } from 'zustand';
import { persist } from 'zustand/middleware';
import { differenceInDays, parseISO } from 'date-fns';
import { isEndlessMode, isMobile } from '../util';

type Store = {
  demoMode: boolean;
  setDemoMode: (demoMode: boolean) => void;
  badgesCollected: string[];
  previouslyCollectedBadges: string[];
  updateBadgesCollected: () => void;
  badgesCollectedToday: string[] | null;
  stepsTaken: number;
  totalStepsTaken: number;
  tokensCollected: number;
  setTokensCollected: (tokensCollected: number) => void;
  incrStepsTaken: () => void;
  timeTaken: number;
  setTimeTaken: (timeTaken: number) => void;
  totalTimeTaken: number;
  directionsForPath: string[];
  setDirectionsForPath: (directionsForPath: string[]) => void;
  setTotalTimeTaken: (totalTimeTaken: number) => void;
  averageTimeTaken: number;
  setAverageTimeTaken: (averageTimeTaken: number) => void;
  averageStepsTaken: number;
  setAverageStepsTaken: (averageStepsTaken: number) => void;
  perfectGame: boolean;
  setPerfectGame: (perfectGame: boolean) => void;
  noMistakes: boolean;
  setNoMistakes: (noMistakes: boolean) => void;
  completedWithKeysRemaining: boolean;
  setCompletedWithKeysRemaining: (completedWithKeysRemaining: boolean) => void;
  carefulStepper: boolean;
  setCarefulStepper: (carefulStepper: boolean) => void;
  visitedEveryRoom: boolean;
  setVisitedEveryRoom: (visitedEveryRoom: boolean) => void;
  hiddenRoomFound: boolean;
  setHiddenRoomFound: (hiddenRoomFound: boolean) => void;
  hiddenPuzzleSolved: boolean;
  superHiddenRoomFound: boolean;
  setSuperHiddenRoomFound: (hiddenRoomFound: boolean) => void;
  setHiddenPuzzleSolved: (hiddenPuzzleSolved: boolean) => void;
  superHiddenPuzzleSolved: boolean;
  setSuperHiddenPuzzleSolved: (superHiddenPuzzleSolved: boolean) => void;
  activatedPortals: boolean;
  setActivatedPortals: (activatedPortals: boolean) => void;
  usedPortalWhileHoldingItem: boolean;
  setUsedPortalWhileHoldingItem: (usedPortalWhileHoldingItem: boolean) => void;
  beatSmileRoom: boolean;
  setBeatSmileRoom: (beatSmileRoom: boolean) => void;
  foundCakeRoom: boolean;
  setFoundCakeRoom: (foundCakeRoom: boolean) => void;
  ateCake: boolean;
  setAteCake: (ateCake: boolean) => void;
  beatGameWithArtifactsRemaining: boolean;
  setBeatGameWithArtifactsRemaining: (
    beatGameWithArtifactsRemaining: boolean,
  ) => void;
  greenThumb: boolean;
  setGreenThumb: (greenThumb: boolean) => void;
  plantKiller: boolean;
  setPlantKiller: (plantKiller: boolean) => void;
  foundWrongWarp: boolean;
  setFoundWrongWarp: (foundWrongWarp: boolean) => void;
  dailyStreak: number;
  totalPlayed: number;
  totalPlayedSinceTrackingAverageStepsTaken: number;
  maxDailyStreak: number;
  lastCompletedDay: string | null;
  updateStatistics: () => void;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  helpDialogOpen: boolean;
  setHelpDialogOpen: (open: boolean) => void;
  thankYouDialogOpen: boolean;
  setThankYouDialogOpen: (open: boolean) => void;
  today: string | null;
  setToday: (today: string) => void;
  endlessModePurchased: boolean;
  setEndlessModePurchased: (endlessModePurchased: boolean) => void;
  showHUD: boolean;
  setShowHUD: (showHUD: boolean) => void;
};
const storeFunction = (set: SetState<Store>, get: GetState<Store>): Store => ({
  demoMode: false,
  setDemoMode: demoMode => set({ demoMode }),
  badgesCollected: [],
  previouslyCollectedBadges: [],
  badgesCollectedToday: null,
  tokensCollected: 0,
  setTokensCollected: tokensCollected => set({ tokensCollected }),
  updateBadgesCollected: () => {
    const store = get();
    const badges: string[] = [];
    if (store.totalPlayed === 1) {
      badges.push('✨');
    }

    if (store.tokensCollected >= 10) {
      badges.push('💰');
    }

    if (
      store.lastCompletedDay !== null &&
      differenceInDays(new Date(), parseISO(store.lastCompletedDay)) > 1
    ) {
      badges.push('🚪');
    }

    if (store.timeTaken < 60) {
      badges.push('⚡️');
    } else if (store.timeTaken < 90) {
      badges.push('⏩');
    } else if (store.timeTaken < 120) {
      badges.push('💨');
    }

    if (store.timeTaken === 4 * 60 + 20 || store.stepsTaken === 420) {
      badges.push('🌿');
    }

    if (store.greenThumb) {
      badges.push('🌷');
    }

    if (store.plantKiller) {
      badges.push('✂️');
    }

    if (store.carefulStepper) {
      badges.push('👠');
    }

    if (store.completedWithKeysRemaining) {
      badges.push('⤴️');
    }

    if (store.perfectGame) {
      badges.push('⭐️');
    }

    if (store.noMistakes) {
      badges.push('🧠');
    }

    if (store.hiddenPuzzleSolved) {
      badges.push('❗️');
    } else if (store.hiddenRoomFound) {
      badges.push('❓');
    }

    if (store.superHiddenPuzzleSolved) {
      badges.push('👁');
    } else if (store.superHiddenRoomFound) {
      badges.push('🤫');
    }

    if (store.ateCake) {
      badges.push('🍰');
    } else if (store.foundCakeRoom) {
      badges.push('🎂');
    }

    if (store.foundWrongWarp) {
      badges.push('🍀');
    }

    if (store.activatedPortals) {
      badges.push('🎩');
    }

    if (store.usedPortalWhileHoldingItem) {
      badges.push('🐇');
    }

    if (store.beatSmileRoom) {
      badges.push('😑');
    }

    if (store.beatGameWithArtifactsRemaining) {
      badges.push('💕');
    }

    if (store.visitedEveryRoom) {
      badges.push('🔎');
    }
    if (store.dailyStreak >= 100) {
      badges.push('👑');
    } else if (store.dailyStreak >= 50) {
      badges.push('🍾');
    } else if (store.dailyStreak >= 20) {
      badges.push('🎊');
    } else if (store.dailyStreak >= 10) {
      badges.push('🎉');
    } else if (store.dailyStreak >= 5) {
      badges.push('🎈');
    } else if (store.dailyStreak >= 2) {
      badges.push('🙌');
    }

    if (isMobile()) {
      badges.push('📱');
    }
    set({
      badgesCollectedToday: badges,
      badgesCollected: Array.from(
        new Set([...get().badgesCollected, ...badges]),
      ),
      previouslyCollectedBadges: [...get().badgesCollected],
    });
  },
  stepsTaken: 0,
  timeTaken: 0,
  totalTimeTaken: 0,
  totalStepsTaken: 0,
  averageTimeTaken: 0,
  averageStepsTaken: 0,
  dailyStreak: 0,
  totalPlayed: 0,
  directionsForPath: [],
  setDirectionsForPath: directionsForPath => set({ directionsForPath }),
  totalPlayedSinceTrackingAverageStepsTaken: 0,
  maxDailyStreak: 0,
  visitedEveryRoom: false,
  lastCompletedDay: null,
  perfectGame: false,
  noMistakes: false,
  carefulStepper: false,
  setCarefulStepper: (carefulStepper: boolean) => set({ carefulStepper }),
  completedWithKeysRemaining: false,
  setCompletedWithKeysRemaining: (completedWithKeysRemaining: boolean) =>
    set({ completedWithKeysRemaining }),
  hiddenRoomFound: false,
  setHiddenRoomFound: hiddenRoomFound => set({ hiddenRoomFound }),
  superHiddenRoomFound: false,
  setSuperHiddenRoomFound: superHiddenRoomFound =>
    set({ superHiddenRoomFound }),
  hiddenPuzzleSolved: false,
  setHiddenPuzzleSolved: hiddenPuzzleSolved => set({ hiddenPuzzleSolved }),
  superHiddenPuzzleSolved: false,
  setSuperHiddenPuzzleSolved: superHiddenPuzzleSolved =>
    set({ superHiddenPuzzleSolved }),
  activatedPortals: false,
  setActivatedPortals: activatedPortals => set({ activatedPortals }),
  usedPortalWhileHoldingItem: false,
  setUsedPortalWhileHoldingItem: usedPortalWhileHoldingItem =>
    set({ usedPortalWhileHoldingItem }),
  setVisitedEveryRoom: visitedEveryRoom => set({ visitedEveryRoom }),
  setPerfectGame: perfectGame => set({ perfectGame }),
  setNoMistakes: noMistakes => set({ noMistakes }),
  incrStepsTaken: () => set({ stepsTaken: get().stepsTaken + 1 }),
  setTimeTaken: timeTaken => set({ timeTaken }),
  setTotalTimeTaken: totalTimeTaken => set({ totalTimeTaken }),
  setAverageTimeTaken: averageTimeTaken => set({ averageTimeTaken }),
  setAverageStepsTaken: averageStepsTaken => set({ averageStepsTaken }),
  dialogOpen: false,
  setDialogOpen: open => {
    document.getElementById('ui')!.style.pointerEvents = 'all';
    set({ dialogOpen: open });
  },
  greenThumb: false,
  setGreenThumb: greenThumb => set({ greenThumb }),
  plantKiller: false,
  setPlantKiller: plantKiller => set({ plantKiller }),
  foundWrongWarp: false,
  setFoundWrongWarp: foundWrongWarp => set({ foundWrongWarp }),
  helpDialogOpen: false,
  setHelpDialogOpen: open => set({ helpDialogOpen: open }),
  thankYouDialogOpen: false,
  setThankYouDialogOpen: open => set({ thankYouDialogOpen: open }),
  beatSmileRoom: false,
  setBeatSmileRoom: beatSmileRoom => set({ beatSmileRoom }),
  foundCakeRoom: false,
  setFoundCakeRoom: foundCakeRoom => set({ foundCakeRoom }),
  ateCake: false,
  setAteCake: ateCake => set({ ateCake }),
  beatGameWithArtifactsRemaining: false,
  setBeatGameWithArtifactsRemaining: beatGameWithArtifactsRemaining =>
    set({ beatGameWithArtifactsRemaining }),
  updateStatistics: () => {
    const { lastCompletedDay, maxDailyStreak, dailyStreak } = get();
    set({
      totalPlayed: get().totalPlayed + 1,
      tokensCollected: get().tokensCollected + 1,
    });
    set({
      totalPlayedSinceTrackingAverageStepsTaken:
        get().totalPlayedSinceTrackingAverageStepsTaken + 1,
    });
    set({ totalTimeTaken: get().totalTimeTaken + get().timeTaken });
    set({ averageTimeTaken: get().totalTimeTaken / get().totalPlayed });
    set({ totalStepsTaken: get().totalStepsTaken + get().stepsTaken });
    set({
      averageStepsTaken: get().totalStepsTaken / get().totalPlayed,
    });
    if (
      lastCompletedDay === null ||
      differenceInDays(new Date(), parseISO(lastCompletedDay)) > 1
    ) {
      set({
        dailyStreak: 1,
        maxDailyStreak: Math.max(maxDailyStreak, dailyStreak, 1),
      });
    } else {
      set({
        dailyStreak: get().dailyStreak + 1,
        maxDailyStreak: Math.max(maxDailyStreak, get().dailyStreak + 1),
      });
    }
  },
  today: null,
  setToday: today => {
    set({ today });
  },
  endlessModePurchased: false,
  setEndlessModePurchased: endlessModePurchased =>
    set({ endlessModePurchased }),

  showHUD: false,
  setShowHUD: showHUD => set({ showHUD }),
});
// eslint-disable-next-line import/prefer-default-export

const useStore = create<Store>(
  persist((set, get) => storeFunction(set, get), {
    name: isEndlessMode() ? 'endlessModeStore' : 'store',
  }),
);

export default useStore;
