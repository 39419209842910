import { format } from 'date-fns';
import Phaser from 'phaser';
import Dude from './Dude';
import Dungeon from './Dungeon';
import useStore from './react/useStore';
import Main from './scenes/Main';
import { start } from './util';

export default class Exit {
  sprite: Phaser.GameObjects.Sprite;

  particles: Phaser.GameObjects.Particles.ParticleEmitterManager;

  tilePosition: number[];

  addToScene(scene: Main, tilePosition: number[]) {
    this.tilePosition = tilePosition;
    this.sprite = scene.add.sprite(
      Dungeon.TILE_SIZE * tilePosition[1],
      Dungeon.TILE_SIZE * tilePosition[0],
      'warp',
      0,
    );
    scene.anims.create({
      key: 'exit-idle',
      frames: scene.anims.generateFrameNumbers('warp', { start: 0, end: 3 }),
      frameRate: 10,
      repeat: -1,
    });
    this.sprite.play('exit-idle');
    this.sprite.setDepth(1);
    this.sprite.scale = 0;
    this.sprite.tint = 0x18f752;
    if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
      this.sprite.setPipeline('Light2D');
    }
    scene.add.tween({
      targets: this.sprite,
      scale: 1,
      duration: 500,
    });
    scene.time.addEvent({
      delay: 500,
      callback: () => {
        this.particles = scene.add.particles('particle');
        const emitter = this.particles.createEmitter({
          x: this.sprite.x,
          y: this.sprite.y,
          // angle: this.sprite.rotation,
          // speed: { min: -100, max: 100 },
          lifespan: 300,
          quantity: 2,
          blendMode: 'ADD',
          speedY: -20,
          emitZone: {
            type: 'random',
            /* @ts-ignore */
            source: new Phaser.Geom.Circle(0, 0, 8),
          },
          tint: 0x3ca370,
          alpha: { start: 1, end: 0 },
        });
        emitter.start();
      },
    });
  }

  update(dude: Dude, scene: Main): void {
    if (
      dude.tilePosition[0] === this.tilePosition[0] &&
      dude.tilePosition[1] === this.tilePosition[1]
    ) {
      if (format(start, 'yyyy-MM-dd') === '2022-05-13') {
        scene.cameras.main.shake(500, 0.0005);
      }
      dude.done = true;
      scene.cameras.main.stopFollow();
      scene.time.addEvent({
        delay: 300,
        callback: () => {
          dude.exiting = true;
          dude.tilePosition[0] -= 1;
        },
      });
      scene.add.tween({
        targets: dude.sprite,
        alpha: 0,
        duration: 400,
        delay: 300,
      });
      scene.add.tween({
        targets: dude.sprite,
        scaleX: 0,
        duration: 400,
        delay: 300,
      });
      scene.add.tween({
        targets: dude.sprite,
        scaleY: 2,
        duration: 400,
        delay: 300,
      });
      scene.time.addEvent({
        delay: 1000,
        callback: () => {
          if (!useStore.getState().dialogOpen) {
            scene.game.destroy(true);
            useStore
              .getState()
              .setCarefulStepper(
                useStore.getState().stepsTaken <
                  scene.dungeon.rooms.flat().filter(Boolean).length * 37,
              );
            useStore
              .getState()
              .setCompletedWithKeysRemaining(scene.dungeon.keys.length > 0);
            useStore
              .getState()
              .setBeatGameWithArtifactsRemaining(
                scene.dungeon.artifacts.length < 3,
              );
            useStore.getState().updateStatistics();
            useStore.getState().updateBadgesCollected();
            useStore.setState({ lastCompletedDay: new Date().toISOString() });
            useStore.getState().setDialogOpen(true);

            scene.elapsedTimer.destroy();
          }
        },
      });
    }
  }
}
