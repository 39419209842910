import { format, startOfDay } from 'date-fns';
import Phaser from 'phaser';

export const isEndlessMode = (): boolean =>
  localStorage.getItem('mode') === 'endless';

export const getMode = (): 'daily' | 'endless' =>
  (localStorage.getItem('mode') as 'daily' | 'endless' | null) || 'daily';

export const setMode = (mode: 'daily' | 'endless'): void => {
  localStorage.setItem('mode', mode);
  location.reload();
};

export const start =
  process.env.NODE_ENV === 'development' || isEndlessMode()
    ? new Date()
    : startOfDay(new Date());

const urlParams = new URLSearchParams(window.location.search);
const seed = urlParams.has('s')
  ? urlParams.get('s')!
  : format(start, 'yyyy-MM-dd');

export const random = new Phaser.Math.RandomDataGenerator([
  (process.env.NODE_ENV === 'development' || isEndlessMode()) &&
  !urlParams.has('s')
    ? start.getTime().toString()
    : seed,
]);

console.log(start.getTime().toString());

export const wrongWarpRandom = new Phaser.Math.RandomDataGenerator(
  urlParams.has('s') ? start.getTime().toString() : undefined,
);

export const DEFAULT_ALPHA = process.env.NODE_ENV === 'development' ? 1 : 0;

export const daysSinceGameWentPublic = Math.floor(
  (new Date().getTime() - new Date(2022, 2, 16).getTime()) / 86400000,
);

export function shuffleArray<T>(array: Array<T>): void {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = random.integerInRange(0, i);
    // eslint-disable-next-line no-param-reassign
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export const isMobile = (): boolean => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return (
    !!userAgent.match(/iPad/i) ||
    !!userAgent.match(/iPhone/i) ||
    !!userAgent.match(/iPod/i) ||
    !!userAgent.match(/Android/i) ||
    !!userAgent.match(/webOS/i) ||
    !!userAgent.match(/Windows Phone/i) ||
    !!userAgent.match(/BlackBerry/i) ||
    !!userAgent.match(/Windows Phone/i) ||
    !!userAgent.match(/iemobile/i)
  );
};
